.screen {
  background: black;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title {
  color: rgb(255, 255, 255);
  letter-spacing: 0.3rem;
  float: left;
  font-size: 1.3vw;
  text-align: center;
  position: relative;
  margin: 0;
}
.spinner-grow {
  margin-left: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  position: relative;
  justify-content: center;
  align-items: center;
}
.load-wrapper {
  width: calc( 3vw * 9 );
  display: flex;
  justify-content: center;
}

@media only screen and (max-device-width: 768px) {
  .title {
    font-size: 3vw;
  }
}
