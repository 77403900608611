#history-area {
    padding-bottom: 5rem;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #ecf1f5;
}

.history-card {
    background-color: #ffffff;
}

.history-year {
    position: relative;
    font-size: 5rem;
    color: #fff;
    text-shadow: 0 0 5px rgb(68, 68, 68);
    top: -5rem;
}

.history-image {
    position: relative;
    height: 22rem;
    top: -4rem;
}

.history-title {
    color: #343a40;
    font-weight: 650;
}

.history-padding {
    padding: 6rem;
    padding-top: 10rem;
    padding-bottom: 0rem;
}

.history-content {
    text-align: justify;
}

.img-div-left {
    float: left;
}

.img-div-right {
    float: right;
}

.img-div-left,
.img-div-right {
    height: '20rem'
}

@media screen and (min-width:319px) and (max-width: 800px) {
    #history-area {
        padding-bottom: 4rem;
        margin: 0;
        width: 100%;
        height: 100%;
    }

    .history-year {
        position: relative;
        font-size: 4rem;
        color: #fff;
        text-shadow: 0 0 5px rgb(68, 68, 68);
        top: -4rem;
        padding: 0 !important;
    }

    .history-image {
        position: relative;
        height: auto;
        width: 100%;
        top: 0rem !important;
        left: 0 !important;
        right: 0 !important;
    }

    .history-padding {
        padding: 0.5rem;
        padding-top: 5rem;
        padding-bottom: 0rem;
    }

    .history-card {
        padding: 1rem;
    }

    .img-div-left,
    .img-div-right {
        float: none !important;
        height: fit-content
    }
}

@media only screen and (min-width:768px) and (max-width:800px) {
    .img-div-left,.img-div-right {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .history-image{
        height: 20rem;
        width: auto;
    }
}

@media screen and (min-width:801px) and (max-width:1400px) {
    .history-padding{
        padding: 0.5rem;
        padding-top: 5rem;
    }
    .img-div-left .history-image {
        position: relative;
        height: auto;
        width: 100%;
        top: -2rem ;
        left: -3rem !important;
    }
    .img-div-right .history-image {
        position: relative;
        height: auto;
        width: 100%;
        top: -2rem;
        right: -3rem !important;
    }
}