#responsibility-area {
  background-color: #ecf1f5;
}
.responsibility-card {
  background-color: rgba(9, 14, 75, 0.075);
  padding: 2rem;
}


#responsibility-card.active {
  animation: responsibilityCard 1s ease 0s 1 normal forwards;
}


@keyframes responsibilityCard {
  0% {
		transform: translateY(-50px);
	}

	100% {
		transform: translateY(0px);
	}
}
