#about-us-banner {
  background-image: linear-gradient(
      to bottom,
      rgb(0, 0, 0, 0.5),
      rgb(0, 0, 0, 0.5)
    ),
    url(../../../assets/img/event/event-img.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 5rem 0;
  z-index: 1;
}

/* This effected both home banner buttons and about us banner button wrongly */
/* 
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

/* .btn-brand,
.btn-brand-rev {
  text-transform: uppercase;
} */

/* .btn-brand {
  background-color: #3b5998;
  color: #fff;
  padding: 15px 30px;
  font-weight: 800;
  border-radius: 0;
} */

/* .btn-brand,
.btn-brand-rev {
  padding: 1rem 2rem;
  border-radius: 0px;
} */

/* .btn-brand:active,
.btn-brand:focus,
.btn-brand:hover {
  background-color: #fff;
  color: #3a3b3c;
} */

/* .btn-brand-rev {
  font-weight: 800;
  border-radius: 0;
  background-color: #fff;
  color: #3a3b3c;
} */

/* .btn-brand-rev:active,
.btn-brand-rev:focus,
.btn-brand-rev:hover {
  background-color: #3b5998;
  color: #fff;
} */
