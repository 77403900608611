#about-area .about-area-wrapper:before {
  background-image: url(../../../assets/img/mission/mission.jpg);
  background-size: cover;
  content: "";
  position: absolute;
  width: 39%;
  height: 85%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 2;
}
#about-area .about-area-wrapper {
  position: relative;
  /* margin-top: 6rem; */
  z-index: 1;
}

#about-area .about-area-wrapper.active {
  animation: missionCard 3s ease 0s 1 normal forwards;
  /* animation: missionCardPhone 3s ease 0s 1 normal forwards; */
}

/* Animation for tablet devices */
@media only screen and (min-device-width: 320px) and (max-device-width: 899px) {
  @keyframes missionCard {
    0% {
      opacity: 0;
      transform: rotateX(70deg);
      transform-origin: top;
    }

    100% {
      opacity: 1;
      transform: rotateX(0deg);
      transform-origin: top;
    }
  }
  #about-area .about-area-wrapper {
    margin-top: -6rem;
  }
}

/* Animation for mobile L devices */
@media only screen and (min-device-width: 900px) and (max-device-width:2560px) {
  @keyframes missionCard {
    0% {
      transform: translateX(-50%);
    }

    100% {
      transform: translateY(0);
    }
  }
  #about-area .about-area-wrapper {
    margin-top: 5rem;
  }
}
