.pre-header-area {
  background-color: #161f37;
  color: #fff;
}

.pre-header-area a {
  color: white;
  text-decoration: none;
  font-size: 0.85rem;
}
.pre-header-area a:hover {
  color: white;
  text-decoration: none;
  font-size: 0.85rem;
}
.pre-header-area a::after {
  color: white;
  text-decoration: none;
  font-size: 0.85rem;
}
.pre-header-area a::before {
  color: white;
  text-decoration: none;
  font-size: 0.85rem;
}
.pre-header-area a:active {
  color: white;
  text-decoration: none;
  font-size: 0.85rem;
}
.pre-header-area .pre-header-left a strong {
  font-size: 0.6rem;
  line-height: 2.5600000000000005rem;
}
.pre-header-left {
  justify-items: center;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

/* For laptop */
@media only screen and (min-width: 768px) {
  .pre-header-area .pre-header-left a {
    display: inline-block;
    margin-left: 1rem;
    line-height: 2.2399999999999998rem;
  }
}

/* For mobile L devices  */
@media only screen and (min-width: 425px) {
  .pre-header-area .pre-header-left a {
    display: inline-block;
    margin-left: 1rem;
  }
}

/* For mobile M devices  */
@media only screen and (min-width: 375px) {
  .pre-header-area .pre-header-left a {
    display: inline-block;
    padding-left: 1.5rem;
    line-height: 2.2399999999999998rem;
  }
}
@media only screen and (max-width: 375px) {
  .pre-header-area .pre-header-left a {
    display: inline-block;
    padding-left: 0.7rem;
    line-height: 2.2399999999999998rem;
  }
}
/* For mobile s devices  */
@media only screen and (min-width: 320px) {
  .pre-header-area .pre-header-left a {
    display: inline-block;
    margin-left: 0rem;
    line-height: 1.2399999999999998rem;
  }
}
