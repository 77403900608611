/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  display: none;
  opacity: 0;
  background: linear-gradient(-45deg, #161f37, red, #d0d010, rgb(3, 247, 3));
  background-size: 400% 400%;
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: fixed; /* Sit on top of the screen */
  z-index: 2;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  display: initial;
  animation: gradient 3s ease infinite;
  animation: fadein 1s ease;
  opacity: 1;
}
#snackbar.hide {
  animation: fade_out 1s ease;
  opacity: 0;
  display: none;
}
/* Animations to fade the snackbar in and out */

@keyframes fade_out {
  0% {
    opacity: 1;
    display: initial;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
