/* Scroll Top button */
#scroll-top {
    cursor: pointer;
    position: fixed;
    z-index: 0;
    opacity: 0;
    top: 87vh;
    right: 0;
    margin-right: 1rem;
    border-radius: 5rem;
    width: 3rem;
    height: 3rem;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 1px 1px rgb(143, 143, 143);
  }
  #scroll-top:hover {
    background-color: #3b5998;
    box-shadow: 1px 1px 1px 1px #3b5998;
    color: #fff;
  }
  