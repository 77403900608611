.card{
border-radius: 5px;
box-shadow: 0px 0px 3px 0px rgb(235, 235, 235);
padding-top: 1rem;
padding-bottom: 1rem;
min-width: 20vw;
}
.card i{
    font-size: medium;
    font-weight: bolder;
    font-size: 1rem;
}
.card p{
    margin-top: 1rem;
}
.note{
    font-size: .7rem;
    font-weight: 100;
    line-break: loose;
    font-family:'Times New Roman', Times, serif;
    margin-top: 1rem;
}

.activateCard{
    /* cursor: pointer; */
    transform: scale(0.9);
    border: 2px solid blue;
}

.list{
    display: flex;
    flex-direction: column;
    list-style-type: circle;
}