.temporary-registration {
  width: 100%;
  margin-top: 1rem;
  font-weight: bold;
}
.mandatory-card {
  background-color: rgb(244, 244, 244);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 1px 1px 1px 1px rgb(211, 210, 210);
  margin-top: 1rem;
  border-radius: 10px;
}
.mandatory-field {
  float: left;
}

.communication-card {
  background-color: rgb(244, 244, 244);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 1px 1px 1px 1px rgb(211, 210, 210);
  margin-top: 1rem;
  border-radius: 10px;
}
.communication-field {
  float: left;
}
.current-address {
  float: left;
}
.address2 {
  font-weight: 100;
  font-size: small;
  float: right;
}
.stric-margin {
  margin-left: 0.5px;
}
.education-card {
  background-color: rgb(244, 244, 244);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 1px 1px 1px 1px rgb(211, 210, 210);
  margin-top: 1rem;
  border-radius: 10px;
}
.education-field {
  float: left;
}
.add-education {
  float: right;
}
.education-box {
  background: white;
  margin-top: 1rem;
  border-radius: 10px;
  font-style: italic;
  box-shadow: 1px 1px 1px 1px rgb(219, 217, 217);
}
.education-box p {
  float: left;
  padding-left: 7.5rem;
}
/* Back Button for below 1024px devices */
.tempForm-back-button {
  background-color: #000000;
  border: none;
  border-radius: 20px;
  font-size: small;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #ffffff;
  width: auto;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
}
/* Back Button  for above 1024px devices*/
@media only screen and (min-device-width: 1024px) {
  .tempForm-back-button {
    background-color: #000000;
    border: none;
    color: #ffffff;
    width: 70px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 8px;
    border-radius: 20px;
    transition: all 0.5s;
    cursor: pointer;
  }
  .tempForm-back-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  .tempForm-back-button span::after {
    content: "Home";
    margin-top: 2px;
    padding-right: 2px;
    font-size: small;
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  .tempForm-back-button:hover span::after {
    opacity: 1;
    right: 0;
  }
  .tempForm-back-button span {
    opacity: 1;
    transition: all 0.5s;
  }
  .tempForm-back-button:hover span {
    padding-right: 35px;
  }
}
