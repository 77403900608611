#footer-area .footer-widget {
  background-color: #161f37;
  color: #b6c1cd;
}
#footer-area .footer-bottom {
  background-color: #3b5998;
  color: #fff;
  padding: 20px 0;
}
#footer-area .footer-widget .single-widget-wrap {
  margin-bottom: 5px;
  margin-top: -55px;
}

#footer-area .footer-widget .single-widget-wrap .widget-title {
  color: #fff;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 3.5200000000000005rem;
  /* margin-bottom: 25px; */
}

#footer-area .footer-widget .single-widget-wrap .footer-about p {
  text-align: justify;
  padding-bottom: 0;
}

.widget-body p {
  text-align: justify;
}

.section-padding {
  padding: 116px 0 0px;
}
#copy-rights {
  font-size: 1rem;
}
#powered-by {
  font-size: 1rem;
  letter-spacing: .2rem;
}
#footer-area .footer-widget {
  background-color: #161f37;
  color: #b6c1cd;
}


#footer-area .footer-widget .single-widget-wrap .newsletter-form form input {
  background-color: #19294b;
  border: 1px solid transparent;
  color: #eaeaea;
  padding: 12px 70px 12px 15px;
  width: 100%;
}

#footer-area .footer-widget .single-widget-wrap .newsletter-form form button {
  background-color: #3b5998;
  color: #fff;
  border: none;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  height: 100%;
}

#footer-area .footer-bottom {
  background-color: #3b5998;
  color: #fff;
  padding: 20px 0;
}

#footer-area a {
  color: #fff;
}

b,
strong {
  font-weight: bolder;
}

a {
  text-decoration: none;
}

#footer-area .footer-widget .single-widget-wrap .newsletter-form {
  margin: 20px 0;
}

#footer-area .footer-widget .single-widget-wrap .newsletter-form form {
  position: relative;
}

input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  text-transform: none;
}


@media screen and (min-device-width: 320px) and(max-device-width:767px) {
  .single-widget-wrap {
    padding-top: 30rem;
  }
}

@media only screen and (min-width: 991px) {
  .section-padding {
    padding: 11px 0px 80px 0px;
  }
}
@media only screen and (max-device-width:991px) {
  #footer-area .footer-widget .single-widget-wrap {
    margin-bottom: 50px;
    margin-top: -55px;
  }
}

@media only screen and (min-width: 768px) {
  .section-padding {
    padding: 70px 0;
  }
}

@media only screen and (min-width: 576px) {
  .section-padding {
    padding: 70px 0;
  }
}

@media screen and (max-device-width: 425px) {
  #copy-rights {
    font-size: .7rem;
  }
  #powered-by {
    font-size: .7rem;
    letter-spacing: .2rem;
  }
}
