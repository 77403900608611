.gallery_item:hover {
  transform: scale(1.1);
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.76);
}
.photos-heading{
  font-size: small;
  font-weight: 600;
  text-align: center;
}
.photos-description {
  text-align: center;
}
.gallery-container{
  padding-left: 2rem;
  padding-right: 2rem;
}
.gallery-info {
  margin-top: 1rem;
}
.category-buttons {
  position: sticky;
  top: 15vh;
  z-index: 2;
}
@media only screen and (max-device-width: 762px) {
  .category-buttons {
    top: 13vh;
  }
  .gallery-info {
    justify-content: center;
    align-items: center;
    text-align: justify;
  }
  .photos-description {
    text-align: center;
  }
  .photos-heading{
    font-size: small;
    font-weight: 600;
    text-align: center;
  }
  .gallery-container{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
