/*================================ Style for nav bar& nav items ======================================== */
.nav_title {
  float: left;
  margin-top: 0rem;
  font-weight: bolder;
  letter-spacing: 0.07rem;
  font-size: 1rem;
}

#nav_title_sub {
  color: #3b5998;
}

.wmo_logo {
  width: 70px;
  margin-top: 0rem;
  float: left;
}

.menu-icons {
  width: 30px;
}

.item {
  border-bottom: 5px transparent;
  padding-top: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}

.item.active {
  background-color: rgba(0, 119, 255, 0.224);
  border-bottom: 5px solid #1d3f9a;
  padding-top: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  /* margin-right: 0.2rem; */
  /* font-weight: bold; */
  cursor: all-scroll;
}

.nav-toggle-btn {
  border: none;
}
.navbar-light .navbar-toggler {
  color: transparent;
  border: none;
}

/*====================== For mobile s devices ======================================================= */
@media only screen and (min-width: 320px) and (max-width: 354px) {
  .nav_title {
    float: left;
    margin-top: 0rem;
    font-weight: bolder;
    letter-spacing: 0.07rem;
    font-size: 0.9rem;
  }
  .wmo_logo {
    width: 70px;
    margin-top: 0rem;
    float: left;
    /* margin-left: -1rem; */
  }
}
/*=====================For above 768px width devices ================================================*/
@media only screen and (min-device-width: 768px) {
  .item {
    border-bottom: 5px transparent;
    padding-top: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 0.2rem;
    cursor: pointer;
  }
  .item:hover {
    background-color: rgba(0, 119, 255, 0.224);
    border-bottom: 5px solid #1d3f9a;
    margin-right: 0.2rem;
  }
}
