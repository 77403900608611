.member-card{
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
    align-content: center !important;
}
.member-image{
    width: 17rem;
    height: 24rem;
}
.member-label{
    position: relative;
    justify-self: center;
    align-self: center;
    padding: 1rem;
    background-color: #3b5998;
    color: #fff;
    /* margin-left: 4rem;
    margin-right:4rem ; */
    top: -3rem;
}
.member-name{
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
}
.member-position{
    margin: 0;
}