#upcoming-area {
  position: relative;
  z-index: 1;
}
#upcoming-area {
  opacity: 1;
  transform: translateY(-20px);
}
#upcoming-area.active{
  animation: cardPopUp .5s ease 0s 1 normal forwards;
}

#btn-brand{
  animation: buttonBlinking 2s cubic-bezier(0.16, 1, 0.3, 1) 0s infinite normal none;
}

@media only screen and (min-width: 992px) {
  @keyframes cardPopUp {
    0% {
      opacity: 0;
      transform: translateY(250px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
@media only screen and (max-width: 992px) {
  @keyframes cardPopUp {
    0% {
      opacity: 0;
      transform: translateY(200px);
    }

    100% {
      opacity: 1;
      transform: translateY(-100px);
    }
  }
}

@media only screen and (max-device-width:425px) {
  #upcoming-area {
    opacity: 1;
    transform: translateY(-190px);
  }
}
@media only screen and (width:768px) {
  #upcoming-area {
    opacity: 1;
    transform: translateY(-150px);
  }
}
@keyframes buttonBlinking{
  0%,
	50%,
	100% {
		background: white;
    color:black
	}

	25%,
	75% {
    background-color: #3b5998;
    color: #fff;
  }
}
