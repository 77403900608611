.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
body,
h1,
h2,
h3,
h4,
h5,
h5.title,
h6,
ol,
ul {
  /* font-family: Roboto, sans-serif */
}

.h5 a:active,
.h5 a:focus,
.h5 a:hover,
a,
a:focus,
a:hover,
h5 a:active,
h5 a:focus,
h5 a:hover,
h5.title a:active,
h5.title a:focus,
h5.title a:hover {
  text-decoration: none;
}

.btn-brand,
.btn-brand-rev {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

#upcoming-area .upcoming-event-wrap .upcoming-event-content .owl-nav div,
.btn-auth {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}

html {
  /* font-size: 62.5%; */
  /* font-size: 100%; */
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  color: #3a3b3c;
  width: 100%;
}

.h5 a,
h5 a,
h5.title a {
  color: #131c33;
}

.h1,
h1 {
  /* font-size: 6rem; */
  /* line-height: 9.600000000000001rem; */
  font-weight: 800;
}

.h1 a,
h1 a {
  font-size: inherit;
  font-weight: inherit;
}

.h2,
h2 {
  /* font-size: 5rem; */
  /* line-height: 8rem; */
  font-weight: 700;
}

.h2 a,
h2 a {
  font-size: inherit;
  font-weight: inherit;
}

.h3,
h3 {
  /* font-size: 4rem;
    line-height: 6.4rem; */
  font-weight: 400;
}

.h3 a,
h3 a {
  font-size: inherit;
  font-weight: inherit;
}

.h4,
h4 {
  /* font-size: 3rem;
    line-height: 4.800000000000001rem;
    font-weight: 400 */
}

.h4 a,
h4 a {
  font-size: inherit;
  font-weight: inherit;
}

.h5,
h5 {
  /* font-size: 2.4rem;
    line-height: 3.84rem; */
  font-weight: 400;
}

.h5 a,
h5 a {
  font-size: inherit;
  font-weight: inherit;
}

h5.title {
  margin-bottom: 8px;
}

.h6,
h6 {
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem; */
  font-weight: 500;
}

.h6 a,
h6 a {
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  /* font-size: 1.6rem;
    line-height: 2.5600000000000005rem; */
  color: #3a3b3c;
}

.double-list li {
  float: left;
  width: 50%;
}

p {
  margin-bottom: 10px;
}

a,
a:focus,
a:hover {
  color: #3b5998;
}

strong {
  font-weight: 700;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.form-group textarea.form-control,
textarea,
textarea.form-control {
  line-height: 1.6;
}

.section-padding {
  padding: 116px 0 117px;
}

.section-title {
  margin-bottom: 60px;
}

.section-title h2 {
  color: #131c33;
  /* font-size: 3.6rem;
    line-height: 5.760000000000001rem; */
  line-height: 100%;
  text-transform: capitalize;
}

.btn-brand,
.btn-brand-rev,
.btn-job {
  text-transform: uppercase;
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem; */
}

.display-table {
  display: table;
  width: 100%;
  height: 100%;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-brand {
  background-color: #3b5998;
  color: #fff;
  padding: 15px 30px;
  font-weight: 800;
  border-radius: 0;
}

.btn-brand:focus,
.btn-brand:hover {
  background-color: #fff;
  color: #3a3b3c;
}

.btn-auth,
.btn-auth:hover {
  color: #fff;
}

.btn-auth {
  background-color: #1dd983;
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem; */
  font-weight: 700;
  padding: 15px 35px;
  display: inline-block;
  margin-left: 10px;
  transition: all 0.2s ease-out;
}

.btn-auth-rev,
.btn-auth:hover {
  background-color: #3b5998;
}

.btn-auth:first-child {
  margin-left: 0;
}

.btn-auth-rev:hover {
  background-color: #1dd983;
}

.btn-brand-dark {
  background-color: #263961;
  border-color: #fff;
}

.nice-select,
.nice-select:active,
.nice-select:focus,
.nice-select:hover {
  border-color: #3b5998;
}

.btn-brand-rev {
  padding: 15px 30px;
  font-weight: 800;
  border-radius: 0;
  background-color: #fff;
  color: #3a3b3c;
}

.btn-brand-rev:active,
.btn-brand-rev:focus,
.btn-brand-rev:hover {
  background-color: #3b5998;
  color: #fff;
}

.btn-job {
  background-color: #1dd983;
  color: #fff;
  font-weight: 700;
  display: block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  padding: 10px;
}

.btn-video-play:hover,
.pagination-wrap .pagination li a {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
}

.btn-job:hover {
  background-color: #161f37;
  color: #fff;
}

.btn-expired {
  background-color: #e66767;
}

.btn-video-play {
  width: 60px;
  height: 60px;
  background-color: #fff;
  color: #3b5998;
  display: block;
  /* font-size: 2.5rem;
    line-height: 4rem;
    line-height: 55px; */
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.btn-reg,
.nice-select {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.btn-video-play i.fa {
  padding-left: 5px;
}

.btn-video-play:hover {
  background-color: #3b5998;
  color: #fff;
  transition: all 0.4s ease-out;
}

.btn-loadmore {
  margin-top: 80px;
  display: inline-block;
  padding: 20px 45px;
}

.btn-loadmore:active,
.btn-loadmore:focus,
.btn-loadmore:hover {
  background-color: #161f37;
  color: #fff;
}

.btn-reg {
  background-color: #3b5998;
  color: #fff;
  display: block;
  border-radius: 0;
  /* font-size: 1.6rem;
    line-height: 2.5600000000000005rem; */
  line-height: 1;
  padding: 13px 40px;
}

.btn-reg:focus,
.btn-reg:hover {
  background-color: #263961;
}

::-moz-selection {
  color: #fff;
  background: #3b5998;
}

::selection {
  color: #fff;
  background: #3b5998;
}

.pagination-wrap {
  margin-top: 70px;
}

.pagination-wrap .pagination {
  display: block;
  text-align: center;
}

.pagination-wrap .pagination li {
  margin-right: 10px;
  display: inline-block;
}

.pagination-wrap .pagination li a {
  width: 50px;
  height: 50px;
  padding: 0;
  color: #8b8b8e;
  /* font-size: 2.2rem;
    line-height: 3.5200000000000005rem; */
  line-height: 50px;
  transition: all 0.4s ease-out;
}

.brand-unorderlist li a,
.nice-select .list li {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
}

.pagination-wrap .pagination li a:hover {
  background-color: #5f7ec1;
  color: #fff;
}

.nice-select,
.nice-select .list li.selected:hover,
.nice-select .list li:hover {
  color: #fff;
  background-color: #3b5998;
}

.pagination-wrap .pagination li:last-child {
  margin-right: 0;
}

.nice-select {
  border-radius: 0;
}

.nice-select:after {
  border-color: #fff;
}

.nice-select .list {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  line-height: 1;
}

.nice-select .list li {
  width: 100%;
  transition: all 0.4s ease-out;
}

.nice-select .current {
  margin-right: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
  background-color: #3b5998;
}

.table-responsive::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 10px;
  background-color: rgba(59, 96, 201, 0.3);
}

.table-responsive::-moz-scrollbar-thumb {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
  background-color: #3b5998;
}

.table-responsive::-moz-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 10px;
  background-color: rgba(59, 96, 201, 0.3);
}

.brand-search-form form {
  position: relative;
}

.brand-search-form form input {
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  border: 1px solid #b4b4b4;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 12px 20px;
}

.brand-search-form form input:active,
.brand-search-form form input:focus {
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #3b5998;
}

.brand-search-form form button {
  color: #b4b4b4;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.brand-unorderlist li {
  border-bottom: 1px solid #b4b4b4;
  padding: 8px 0 8px 10px;
}

.brand-unorderlist li a {
  text-transform: capitalize;
  color: #3a3b3c;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  font-weight: 500;
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  -webkit-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
  filter: alpha(opacity=80);
  transition: all 0.4s ease-out;
  position: relative;
}

.brand-unorderlist li a:hover {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  padding-left: 10px;
}

.brand-unorderlist li a:before {
  content: "\f105";
  font-family: FontAwesome;
  /* font-size: 1.6rem;
    line-height: 2.5600000000000005rem; */
  line-height: 1;
  margin-right: 10px;
}

.brand-unorderlist li:last-child {
  border-bottom: none;
}

.blockquote,
blockquote {
  border-top: 1px solid #b4b4b4;
  position: relative;
  /* font-size: 1.8rem !important;
    line-height: 2.8800000000000003rem !important; */
  font-style: italic;
  color: #6a78a1;
  padding: 25px 20px 0 70px;
  margin: 10px 0 0;
}

.blockquote:before,
blockquote:before {
  content: "\f10e";
  font-family: FontAwesome;
  color: #cfcfcf;
  /* font-size: 4rem;
    line-height: 6.4rem; */
  line-height: 1;
  position: absolute;
  left: 0;
  top: 50%;
  padding-top: 30px;
  transform: translateY(-50%);
}

.img-left,
.img-right {
  padding: 0 2rem 1rem 0;
}

.custom-control-label::before {
  width: 13px;
  height: 13px;
  border: 1px solid #6a78a1;
  background-color: transparent;
  top: 0;
}

.custom-control-label::after {
  left: -1.409rem;
  top: 0.06rem;
}

.img-left {
  float: left;
}

.img-right {
  float: right;
}

.typography-page-wrapper img {
  max-width: 500px;
}

#header-area .preheader-area {
  background-color: #161f37;
  color: #fff;
}

#header-area .preheader-area .preheader-left {
  padding: 16px 0;
}

#header-area .preheader-area .preheader-left a {
  color: #fff;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  margin-right: 35px;
}

#header-area .preheader-area .preheader-left a:last-child {
  margin-right: 0;
}

#header-area .preheader-area .preheader-left a strong {
  /* font-size: 1.6rem;
    line-height: 2.5600000000000005rem */
}

#header-area .header-bottom-area .navbar-brand img {
  width: 250px;
}

#header-area .header-bottom-area .main-menu {
  padding: 0;
}

#header-area .header-bottom-area .main-menu li.nav-item {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

#header-area .header-bottom-area .main-menu li.nav-item a.nav-link {
  color: #161f37;
  /* font-size: 1.7rem;
    line-height: 2.72rem; */
  font-weight: 500;
  padding: 40px 20px;
  display: block;
  position: relative;
  margin-right: 20px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item
  .dropdown-menu
  li.nav-item
  a.nav-link:before,
.c-offcanvas .navbar-brand {
  display: none;
}

#header-area .header-bottom-area .main-menu li.nav-item a.nav-link:before {
  background-color: #3b5998;
  content: "";
  bottom: 0;
  left: 0;
  position: absolute;
  height: 6px;
  width: 100%;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

#slider-area .social-networks-icon a,
#slider-area .social-networks-icon a i.fa {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
}

#header-area .header-bottom-area .main-menu li.nav-item:hover a.nav-link {
  background-color: #dbe5f3;
  color: #3b5998;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item:hover
  a.nav-link:before {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  visibility: visible;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item:hover
  .dropdown-menu
  li.nav-item
  a.nav-link {
  background-color: #fff;
  color: #3a3b3c;
}

#header-area .header-bottom-area .main-menu li.nav-item .dropdown-toggle:after {
  vertical-align: middle;
}

#header-area .header-bottom-area .main-menu li.nav-item .dropdown-menu {
  min-width: 200px;
  margin: 0;
  border: none;
  border-top: 2px solid rgba(181, 0, 255, 0);
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item
  .dropdown-menu
  li.nav-item
  a.nav-link {
  margin: 0;
  padding: 10px 20px;
  /* font-size: 1.3rem;
    line-height: 2.08rem; */
  font-weight: 400;
  border-top: 1px solid #eee;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item
  .dropdown-menu
  li.nav-item:first-child
  a.nav-link {
  border-top: 0 solid red;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item
  .dropdown-menu
  li.nav-item:hover
  a.nav-link {
  background-color: #263961;
  border-color: #263961;
  color: #fff;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item
  .dropdown-menu
  li.nav-item:hover
  .dropdown-menu
  li
  a {
  background-color: #fff;
  color: #3a3b3c;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item
  .dropdown-menu
  li.nav-item
  .dropdown-menu {
  left: auto !important;
  right: 100%;
  top: -2px !important;
  border-right: 2px solid rgba(181, 0, 255, 0);
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item
  .dropdown-menu
  li.nav-item
  .dropdown-menu
  li.nav-item
  a.nav-link {
  border-top: 1px solid #eee;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item
  .dropdown-menu
  li.nav-item
  .dropdown-menu
  li.nav-item:hover
  a.nav-link {
  background-color: #263961;
  color: #fff;
  border-top-color: #263961;
}

#header-area .header-bottom-area .main-menu li.nav-item.active .nav-link {
  background-color: #dbe5f3;
  color: #3b5998;
}

#header-area
  .header-bottom-area
  .main-menu
  li.nav-item.active
  .nav-link:before {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  visibility: visible;
}

.header-bottom-area.fixed {
  background: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

#scholership-promo,
#slider-area .single-slide-wrap,
.gallery-gird .col-lg-3 .single-gallery-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#header-area .header-bottom-area.fixed .navbar-brand img {
  width: 150px;
}

#header-area .header-bottom-area.fixed .main-menu li.nav-item a.nav-link {
  padding: 24px 20px;
}

#header-area .header-bottom-area.fixed .main-menu li.active a.nav-link,
#header-area .header-bottom-area.fixed .main-menu li.nav-item:hover a.nav-link {
  background-color: transparent;
  color: #000;
}

.c-offcanvas {
  right: 0;
  padding: 0;
  overflow-y: scroll;
  color: #000;
}

.c-offcanvas.is-open {
  z-index: 999999;
}

.js-offcanvas a {
  color: #000;
  outline: 0;
}

.offcanvaswrap .nav-item .nav-link {
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
}

.offcanvaswrap .dropdown-menu {
  position: static !important;
  transform: none !important;
  left: auto !important;
  top: auto !important;
  border: 0 !important;
  padding-left: 25px;
  display: block !important;
}

#slider-area {
  position: relative;
}

#slider-area .single-slide-wrap {
  background-color: #000;
  color: #fff;
  padding: 155px 0 320px;
  position: relative;
  z-index: 1;
}

#slider-area .single-slide-wrap .slider-content h2 {
  font-size: 2rem;
  line-height: 16rem;
  line-height: 1;
  margin: 0;
  text-shadow: 5px 0px 0px #505050;
}

#slider-area .single-slide-wrap .slider-content h3 {
  line-height: 1;
  /* margin: 20px 0 30px; */
}

#slider-area .single-slide-wrap .slider-content span {
  color: #ffe156;
}

#slider-area .single-slide-wrap .slider-content .slider-btn {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

#slider-area .single-slide-wrap .slider-content .slider-btn a {
  margin-right: 20px;
}

#slider-area .single-slide-wrap .slider-content .slider-btn a:last-child {
  margin-right: 0;
}

#slider-area .single-slide-wrap:after {
  background-color: #000;
  content: "";
  left: 0;
  height: 100%;
  top: 0;
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  -webkit-opacity: 0.7;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=70);
  filter: alpha(opacity=70);
  position: absolute;
  width: 100%;
  z-index: -1;
}

/* #slider-area .owl-dots {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 65px
} */

/* #slider-area .owl-dots .owl-dot span {
    width: 25px;
    height: 25px;
    background-color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 15px;
    line-height: 100%
} */

#slider-area .owl-dots .owl-dot:last-child span {
  margin-bottom: 0;
}

#slider-area .owl-dots .owl-dot.active span {
  border: 3px solid #fff;
  background-color: #3b5998;
  width: 30px;
  height: 30px;
}

#slider-area .social-networks-icon {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

#slider-area .social-networks-icon a {
  border-bottom: 1px solid #fff;
  display: block;
  color: #fff;
  /* font-size: 3rem;
    line-height: 4.800000000000001rem; */
  transition: all 0.4s ease-out;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
}

#slider-area .social-networks-icon a i.fa {
  transition: all 0.4s ease-out;
}

#slider-area .social-networks-icon a:hover i.fa-facebook {
  color: #3b5999;
}

#slider-area .social-networks-icon a:hover i.fa-twitter {
  color: #1da1f2;
}

#slider-area .social-networks-icon a:hover i.fa-pinterest {
  color: #cb2028;
}

#slider-area .social-networks-icon a:hover i.fa-youtube {
  color: #cc332d;
}

#slider-area .social-networks-icon a span {
  position: absolute;
  /* font-size: 1.3rem;
    line-height: 2.08rem; */
  font-weight: 700;
  color: #000;
  display: block;
  background-color: #fff;
  padding: 2px 8px;
  left: 100%;
  margin-left: 30px;
  top: 50%;
  transform: translateY(-50%);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  width: 120px;
}

#slider-area .social-networks-icon a span:after {
  position: absolute;
  content: "";
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  left: -5px;
}

#slider-area .social-networks-icon li a:hover span {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  visibility: visible;
  margin-left: 20px;
}

#slider-area .social-networks-icon li:last-child a {
  border-bottom: none;
}

#upcoming-area .upcoming-event-wrap {
  background-color: #3b5998;
  padding: 40px;
  color: #fff;
  position: relative;
}

#upcoming-area .upcoming-event-wrap .up-event-titile {
  position: absolute;
  text-align: center;
  margin: 0;
  top: -31px;
}

#upcoming-area .upcoming-event-wrap .up-event-titile h4 {
  background-color: #fff;
  /* font-size: 1.5rem;
    line-height: 1.16rem; */
  color: #000;
  text-transform: capitalize;
  font-weight: 700;
  padding: 10px 45px;
  margin: 0;
  box-shadow: 14px 0 26px rgba(0, 0, 0, 0.4);
}

#responsibility-area .single-responsibility:hover img,
.single-job-opportunity:hover {
  -webkit-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
}

#upcoming-area .upcoming-event-wrap .upcoming-event-content .owl-nav {
  position: absolute;
  bottom: -40px;
  right: -35px;
}

#upcoming-area .upcoming-event-wrap .upcoming-event-content .owl-nav div {
  background-color: #5f7ec1;
  width: 45px;
  height: 45px;
  /* font-size: 3.5rem;
    line-height: 5.6000000000000005rem; */
  line-height: 45px;
  text-align: center;
  margin-bottom: 5px;
  transition: all 0.2s ease-out;
}

#responsibility-area .single-responsibility,
.gallery-menu span {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}

#upcoming-area .upcoming-event-wrap .upcoming-event-content .owl-nav div:hover {
  background-color: #fff;
  color: #161f37;
}

.single-upcoming-event .up-event-thumb {
  position: relative;
  min-height: 294px;
}

.single-upcoming-event .up-event-thumb img {
  width: 100%;
  display: block;
}

.single-upcoming-event .up-event-thumb .up-event-date {
  color: #3a3b3c;
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem; */
  font-weight: 700;
  background-color: #fff;
  padding: 12px;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: -9px;
  border-bottom: 8px solid #000;
}

.single-upcoming-event .up-event-text h3 {
  /* font-size: 3rem;
    line-height: 4.800000000000001rem */
}

.single-upcoming-event .up-event-text h3 a {
  color: #fff;
}

.single-upcoming-event .up-event-text a {
  margin-top: 10px;
}

#about-area .about-area-wrapper .about-content-wrap {
  background-color: #ecf1f5;
  border: 10px solid #dbe5f3;
  padding: 36px 10px 40px 260px;
}

#about-area .about-area-wrapper .about-content-wrap .about-thumb {
  display: none;
}

#about-area .about-area-wrapper .about-content-wrap a.btn-brand {
  margin-top: 25px;
}

#about-area .about-area-wrapper .about-content-wrap a.btn-brand:hover {
  background-color: #161f37;
  color: #fff;
}

#gallery-area {
  background-color: #ecf1f5;
}

#about-area .about-area-wrapper .about-content-wrap .section-title {
  margin-bottom: 30px;
}

#responsibility-area .single-responsibility {
  color: #3a3b3c;
  transition: all 0.2s ease-out;
}

#responsibility-area .single-responsibility img,
.single-job-opportunity {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
}

#responsibility-area .single-responsibility img {
  border-radius: 50%;
  transition: all 0.4s ease-out;
}

#responsibility-area .single-responsibility h4 {
  color: #131c33;
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem; */
  font-weight: 500;
  margin: 15px 0 10px;
}

#responsibility-area .single-responsibility:hover h4 {
  color: #3b5998;
}

#responsibility-area .single-responsibility p {
  margin-bottom: 0;
}

#responsibility-area .single-responsibility:hover {
  transform: translateY(-5px);
}

#responsibility-area .single-responsibility:hover img {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
}

/* #funfact-area {
  background-color: #161f37;
  padding: 100px 0;
  color: #fff;
} */

#funfact-area .single-funfact-wrap .funfact-icon {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
}

#funfact-area .single-funfact-wrap .funfact-icon img {
  max-width: 100%;
}

#funfact-area .single-funfact-wrap .funfact-info {
  display: inline-block;
  vertical-align: middle;
  /* font-size: 2rem;
    line-height: 3.2rem; */
  line-height: 1;
  padding-left: 35px;
  text-align: left;
}

#funfact-area .single-funfact-wrap .funfact-info h5 {
  font-weight: 300;
  /* font-size: 3.6rem;
    line-height: 5.760000000000001rem; */
  line-height: 100%;
  margin: 0;
}

#funfact-area .single-funfact-wrap .funfact-info p {
  margin: 0;
}

#job-opportunity .job-opportunity-wrapper {
  margin-top: -30px;
}

.single-job-opportunity {
  border: 1px solid #dbe5f3;
  padding: 30px 0;
  margin-top: 30px;
  color: #3a3b3c;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  transition: all 0.4s ease-out;
}

.gallery-gird .col-lg-3 .single-gallery-item,
.single-job-opportunity .job-opportunity-text h3 a {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
}

.single-job-opportunity .job-opportunity-text {
  padding: 0 20px;
}

.single-job-opportunity .job-opportunity-text .job-oppor-logo {
  height: 80px;
}

.single-job-opportunity .job-opportunity-text .job-oppor-logo img {
  max-width: 80px;
  max-height: 80px;
  margin: auto;
}

.single-job-opportunity .job-opportunity-text h3 {
  margin: 20px 0 25px;
}

.single-job-opportunity .job-opportunity-text h3 a {
  /* font-size: 1.7rem;
    line-height: 2.72rem; */
  line-height: 1.3;
  display: block;
  font-weight: 700;
  color: #131c33;
  transition: all 0.4s ease-out;
}

.single-job-opportunity .job-opportunity-text h3 a:hover {
  color: #3b5998;
}

.single-job-opportunity .btn-job {
  margin-top: 15px;
  display: inline-block;
  padding: 10px 40px;
}

.event-page-content-wrap .all-event-list,
.gallery-gird {
  margin-top: -30px;
}

.gallery-menu a,
.gallery-menu span {
  display: inline-block;
  padding: 15px 35px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.single-job-opportunity:hover {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
}

#page-content-wrap .people-to-say .single-testimonial-wrap:hover,
.single-blog-post:hover {
  -webkit-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
}

.gallery-menu {
  margin-bottom: 90px;
}

.gallery-menu span {
  background-color: #dbe5f3;
  color: #131c33;
  transition: all 0.2s ease-out;
  margin-right: 25px;
}

.gallery-menu span.active,
.gallery-menu span:hover {
  background-color: #3b5998;
  color: #fff;
}

.gallery-menu span:last-child {
  margin-right: 0;
}

.gallery-menu a {
  background-color: #dbe5f3;
  color: #131c33;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  margin-right: 25px;
}

.gallery-menu a.active,
.gallery-menu a:hover {
  background-color: #3b5998;
  color: #fff;
}

.gallery-menu a:last-child {
  margin-right: 0;
}

.gallery-gird .col-lg-3 .single-gallery-item {
  margin-top: 30px;
  height: 250px;
  position: relative;
  transition: all 0.4s ease-out;
  overflow: hidden;
}

.gallery-gird .col-lg-3 .single-gallery-item .gallery-hvr-wrap,
.gallery-gird
  .col-lg-3
  .single-gallery-item
  .gallery-hvr-wrap
  .gallery-hvr-text {
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  position: absolute;
  visibility: hidden;
}

.gallery-gird .col-lg-3 .single-gallery-item .gallery-hvr-wrap {
  background-color: #3b5998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  transition: all 0.4s ease-out;
}

.gallery-gird
  .col-lg-3
  .single-gallery-item
  .gallery-hvr-wrap
  .gallery-hvr-text {
  left: 25px;
  bottom: -55px;
  transition: all 0.4s ease-out;
}

.gallery-gird
  .col-lg-3
  .single-gallery-item
  .gallery-hvr-wrap
  .gallery-hvr-text
  h4 {
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem; */
  font-weight: 700;
}

.gallery-gird
  .col-lg-3
  .single-gallery-item
  .gallery-hvr-wrap
  .gallery-hvr-text
  .gallery-event-date {
  /* font-size: 1.4rem;
    line-height: 1 */
  font-weight: 700;
}

.gallery-gird .col-lg-3 .single-gallery-item .gallery-hvr-wrap .btn-zoom {
  width: 70px;
  height: 65px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  transform: scale(0);
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.gallery-gird .col-lg-3 .single-gallery-item .gallery-hvr-wrap .btn-zoom img {
  margin-top: -25px;
}

.gallery-gird .col-lg-3 .single-gallery-item:hover .gallery-hvr-wrap {
  opacity: 0.9;
  visibility: visible;
}

.gallery-gird .col-lg-3 .single-gallery-item:hover .gallery-hvr-text {
  opacity: 1;
  visibility: visible;
  bottom: 15px;
}

.gallery-gird .col-lg-3 .single-gallery-item:hover .btn-zoom {
  transform: scale(1);
}

.gallery-gird .col-lg-3 .video .btn-video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
}

.gallery-gird .col-lg-3:nth-child(1) .single-gallery-item,
.gallery-gird .col-lg-3:nth-child(4) .single-gallery-item,
.gallery-gird .col-lg-3:nth-child(5) .single-gallery-item {
  height: 315px;
}
/* 
.gallery-gird .gallery-bg-1 {
  background-image: url(../../../assets/img/gallery/gellary-img-1.jpg);
}

.gallery-gird .gallery-bg-2 {
  background-image: url(../../../assets/img/gallery/gellary-img-2.jpg);
}

.gallery-gird .gallery-bg-3 {
  background-image: url(../../../assets/img/gallery/gellary-img-3.jpg);
}

.gallery-gird .gallery-bg-4 {
  background-image: url(../../../assets/img/gallery/gellary-img-4.jpg);
}

.gallery-gird .gallery-bg-5 {
  background-image: url(../../../assets/img/gallery/gellary-img-5.jpg);
}

.gallery-gird .gallery-bg-6 {
  background-image: url(../../../assets/img/gallery/gellary-img-6.jpg);
}

.gallery-gird .gallery-bg-7 {
  background-image: url(../../../assets/img/gallery/gellary-img-7.jpg);
}

.gallery-gird .gallery-bg-8 {
  background-image: url(../../../assets/img/gallery/gellary-img-8.jpg);
} */

#scholership-promo {
  /* background-image: url(../../../assets/img/scholership.png); */
  padding: 100px 0 117px;
}

#scholership-promo .scholership-promo-text {
  /* font-size: 2rem;
    line-height: 3.2rem */
}

#scholership-promo .scholership-promo-text h2 {
  margin-bottom: 25px;
  /* font-size: 4rem;
    line-height: 6.4rem; */
  color: #131c33;
}

#scholership-promo .scholership-promo-text h2 span {
  color: #3b5998;
}

#scholership-promo .scholership-promo-text .btn-brand {
  margin-top: 35px;
}

#scholership-promo .scholership-promo-text .btn-brand:hover {
  background-color: #161f37;
  color: #fff;
}

#blog-area {
  background-color: #ecf1f5;
}

.single-blog-post {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.single-blog-post .blog-thumb {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 0;
}

.single-blog-post .blog-thumb .blog-thumbnail {
  height: 175px;
  position: relative;
}

.single-blog-post .blog-thumb .blog-thumbnail:after {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#000),
    color-stop(50%, rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(
    bottom,
    #000 0,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -o-linear-gradient(
    bottom,
    #000 0,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    0deg,
    #000 0,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  bottom: 0;
  width: 100%;
}

.single-blog-post .blog-thumb .blog-meta {
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;
  padding: 0 30px;
}

.single-blog-post .blog-thumb .blog-meta .author .author-pic {
  display: inline-block;
  vertical-align: middle;
}

.single-blog-post .blog-thumb .blog-meta .author .author-pic img {
  width: 35px;
  height: 35px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.single-blog-post .blog-thumb .blog-meta .author .author-info {
  display: inline-block;
  vertical-align: middle;
  /* font-size: 1.2rem;
    line-height: 1.92rem; */
  line-height: 1;
  margin-left: 5px;
}

.single-blog-post .blog-thumb .blog-meta .author .author-info h5 {
  color: #fff;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  line-height: 1;
  margin-bottom: 3px;
}

.single-blog-post .blog-thumb .blog-meta .author .author-info p {
  margin: 0;
  color: #fff;
}

.single-blog-post .blog-thumb .blog-meta .like-comm {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
}

.single-blog-post .blog-thumb .blog-meta .like-comm a {
  color: #fff;
  /* font-size: 1.2rem;
    line-height: 1.92rem; */
  margin-right: 5px;
}

.single-blog-post .blog-thumb .blog-meta .like-comm a i.fa {
  margin-right: 4px;
}

.single-blog-post .blog-thumb .blog-meta a {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.single-blog-post .blog-thumb .blog-meta a:hover {
  color: #5f7ec1;
}

.single-blog-post .blog-content {
  background-color: #fff;
  padding: 30px;
  color: #3a3b3c;
}

.single-blog-post .blog-content h3 {
  font-weight: 500;
  /* font-size: 2rem;
    line-height: 1.4; */
  margin-bottom: 25px;
}

.single-blog-post .blog-content h3 a {
  color: #131c33;
}

.single-blog-post .blog-content .btn-brand {
  padding: 6px 22px;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 10px;
}

.single-blog-post .blog-content .btn-brand:hover {
  background-color: #161f37;
  color: #fff;
}

.single-blog-post .blog-thumb-1 {
  /* background-image: url(../../../assets/img/blog/blog-1.jpg); */
}

.single-blog-post .blog-thumb-2 {
  /* background-image: url(../../../assets/img/blog/blog-2.jpg); */
}

.single-blog-post .blog-thumb-3 {
  /* background-image: url(../../../assets/img/blog/blog-3.jpg); */
}

.single-blog-post:hover {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

#footer-area a {
  color: #fff;
}

#footer-area .footer-widget {
  background-color: #161f37;
  color: #b6c1cd;
}

#footer-area .footer-widget .single-widget-wrap {
  /* font-size: 1.6rem;
    line-height: 2.5600000000000005rem */
}

#footer-area .footer-widget .single-widget-wrap .footer-about a {
  /* display: inline-block; */
  font-weight: 500;
}

#footer-area .footer-widget .single-widget-wrap .footer-about a:nth-child(1n) {
  margin-right: 15px;
}

#footer-area .footer-widget .single-widget-wrap .newsletter-form {
  margin: 20px 0;
}

#footer-area .footer-widget .single-widget-wrap .newsletter-form form {
  position: relative;
}

#footer-area .footer-widget .single-widget-wrap .newsletter-form form input {
  background-color: #19294b;
  border: 1px solid transparent;
  color: #eaeaea;
  padding: 12px 70px 12px 15px;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  width: 100%;
}

#footer-area
  .footer-widget
  .single-widget-wrap
  .newsletter-form
  form
  input:focus {
  outline: 0;
  border-color: #3b5998;
  background-color: white;
  color: gray;
  box-shadow: none;
}

#footer-area .footer-widget .single-widget-wrap .newsletter-form form button {
  background-color: #3b5998;
  color: #fff;
  border: none;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  /* -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out; */
  height: 100%;
}

#footer-area
  .footer-widget
  .single-widget-wrap
  .newsletter-form
  form
  button:hover {
  background-color: #5f7ec1;
}

#footer-area .footer-widget .single-widget-wrap .footer-social-icons a {
  /* margin-right: 10px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out; */
}

#footer-area .footer-widget .single-widget-wrap .footer-social-icons a i.fa {
  /* width: 35px;
  height: 35px;
  line-height: 35px;
  background-color: #3b60c9;
  display: inline-block;
  text-align: center; */
}

#footer-area
  .footer-widget
  .single-widget-wrap
  .footer-social-icons
  a
  i.fa-twitter {
  background-color: #0cbdff;
}

#footer-area
  .footer-widget
  .single-widget-wrap
  .footer-social-icons
  a
  i.fa-linkedin {
  background-color: #3677d9;
}

#footer-area
  .footer-widget
  .single-widget-wrap
  .footer-social-icons
  a
  i.fa-vimeo {
  background-color: #178cef;
}

#footer-area .footer-widget .single-widget-wrap .footer-social-icons a:hover {
  -moz-opacity: 0.6;
  -khtml-opacity: 0.6;
  -webkit-opacity: 0.6;
  opacity: 0.6;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=60);
  filter: alpha(opacity=60);
}

#footer-area .footer-widget .single-widget-wrap .footer-list li a {
  display: inline-block;
  margin-bottom: 10px;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

#page-title-area .page-title-content .btn-brand,
.scroll-top {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
}

#footer-area .footer-widget .single-widget-wrap .footer-list li a:before {
  font-family: FontAwesome;
  content: "-";
  color: #fff;
  margin-right: 10px;
}

#footer-area .footer-widget .single-widget-wrap .footer-list li a:hover {
  color: #5f7ec1;
  padding-left: 6px;
}

#footer-area .footer-widget .single-widget-wrap .footer-list li a:hover:before {
  color: #5f7ec1;
}

#footer-area .footer-bottom {
  background-color: #3b5998;
  color: #fff;
  padding: 20px 0;
}

#footer-area .footer-bottom .footer-bottom-text p {
  margin: 0;
}

.scroll-top {
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #263961;
  color: #fff;
  display: none;
  /* font-size: 2rem;
    line-height: 3.2rem; */
  line-height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: fixed;
  bottom: 30px;
  right: 20px;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  z-index: 9;
}

.scroll-top:focus,
.scroll-top:hover {
  background-color: #5f7ec1;
  color: #fff;
}

#page-title-area {
  background-image: url(../../../assets/logos/logo.png);
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 100px 0;
  z-index: 1;
}

#page-title-area:after {
  background-color: #000;
  content: "";
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  -webkit-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
  filter: alpha(opacity=80);
  z-index: -1;
}

#page-title-area .page-title-content {
  color: #fff;
}

#page-title-area .page-title-content h1 {
  line-height: 1;
  margin-bottom: 15px;
}

#page-title-area .page-title-content .btn-brand {
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  padding: 12px 55px;
  margin-top: 15px;
}

.gallery-page-wrap {
  background-color: #ecf1f5;
}

.gallery-page-wrap .gallery-menu {
  border-bottom: 5px solid #3b5998;
}

.gallery-page-wrap .single-album-wraper {
  margin-bottom: 150px;
}

.gallery-page-wrap .single-album-wraper .album-heading {
  position: relative;
  margin-bottom: 90px;
}

.gallery-page-wrap .single-album-wraper .album-heading h2 {
  /* font-size: 4.8rem;
    line-height: 7.68rem; */
  font-weight: 100;
  display: inline-block;
  position: relative;
  margin-bottom: 30px;
}

.gallery-page-wrap .single-album-wraper .album-heading h2:after {
  background-color: #161f37;
  content: "";
  left: 0;
  height: 2px;
  position: absolute;
  width: calc(75%);
  bottom: 0;
}

.gallery-page-wrap .single-album-wraper .album-heading h2 a {
  color: #131c33;
}

.gallery-page-wrap .single-album-wraper .album-heading a.btn-brand {
  text-transform: capitalize;
  font-weight: 400;
  position: absolute;
  right: 0;
  top: 15px;
}

.gallery-page-wrap .single-album-wraper .album-heading a.btn-brand:focus,
.gallery-page-wrap .single-album-wraper .album-heading a.btn-brand:hover {
  background-color: #5f7ec1;
  color: #fff;
}

#page-content-wrap .about-page-content-wrap {
  padding-top: 170px;
  background-color: #ecf1f5;
}

#page-content-wrap .about-page-content-wrap .single-about-text {
  background-color: #fff;
  padding: 50px;
  position: relative;
  /* font-size: 1.6rem;
    line-height: 2.5600000000000005rem; */
  margin-top: 150px;
}

#page-content-wrap .about-page-content-wrap .single-about-text .year {
  /* font-size: 10rem;
    line-height: 16rem; */
  /* line-height: 1; */
  color: #fff;
  text-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -75px;
  right: 25px;
}

#page-content-wrap .about-page-content-wrap .single-about-text h2 {
  /* font-size: 3.6rem;
    line-height: 5.760000000000001rem;
    line-height: 1; */
  font-weight: 700;
  margin-bottom: 20px;
}

#page-content-wrap .about-page-area-title,
#page-content-wrap .people-to-say .about-page-area-title {
  margin-bottom: 50px;
}

#page-content-wrap .about-page-content-wrap .single-about-text .img-left {
  margin-top: -100px;
  margin-left: -97px;
}

#page-content-wrap .about-page-content-wrap .single-about-text .img-right {
  margin-top: -100px;
  margin-right: -118px;
}

#page-content-wrap .about-page-content-wrap .single-about-text:first-child img {
  filter: grayscale(100%);
}

#page-content-wrap
  .about-page-content-wrap
  .single-about-text:nth-child(2n)
  .year {
  right: auto;
  left: 0;
}

#page-content-wrap .about-page-content-wrap .single-about-text:first-child {
  margin-top: 0;
}

#page-content-wrap
  .our-honorable-commitee
  .honorable-committee-list
  .single-committee-member {
  margin-top: 50px;
}

#page-content-wrap .about-page-area-title h2 {
  /* font-size: 3.6rem;
    line-height: 5.760000000000001rem;
    line-height: 1 */
}

#page-content-wrap .people-to-say {
  background-color: #ecf1f5;
}

#page-content-wrap .people-to-say .single-testimonial-wrap {
  background-color: #fff;
  position: relative;
  padding: 0 20px 65px;
  margin: 97px 0 40px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  text-align: center;
}

#page-content-wrap .people-to-say .single-testimonial-wrap .people-thumb {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: -65px;
}

#page-content-wrap .people-to-say .single-testimonial-wrap .people-thumb img {
  width: 130px;
  height: 130px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border: 4px solid transparent;
}

.single-committee-member,
.single-committee-member img {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
}

#page-content-wrap .people-to-say .single-testimonial-wrap h4 {
  /* font-size: 2.4rem;
    line-height: 3.84rem;
    line-height: 1; */
  font-weight: 700;
  background-color: #3b5998;
  color: #fff;
  padding: 20px 10px;
  margin-bottom: 0;
  position: absolute;
  right: 0;
  bottom: -40px;
  width: calc(70%);
}

#page-content-wrap .people-to-say .single-testimonial-wrap h4 .people-deg {
  /* font-size: 1.5rem;
    line-height: 2.4000000000000004rem;
    line-height: 1; */
  font-weight: 400;
  color: #fff;
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  -webkit-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
  filter: alpha(opacity=80);
  letter-spacing: 0.7px;
  display: block;
  margin-top: 10px;
}

#page-content-wrap .people-to-say .single-testimonial-wrap .quote-icon {
  display: inline-block;
  margin: 90px 0 15px;
}

#page-content-wrap .people-to-say .single-testimonial-wrap .quote-icon:after {
  /* background-image: url(../img/about-page/quote.png); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  content: "";
  width: 68px;
  display: block;
  height: 52px;
  text-align: center;
}

#page-content-wrap .people-to-say .single-testimonial-wrap:hover {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

#page-content-wrap
  .people-to-say
  .single-testimonial-wrap:hover
  .people-thumb
  img {
  border-color: #3b5998;
}

.committee-content-wrap .committee-member-list {
  margin-top: -80px;
}

.single-committee-member {
  margin-top: 80px;
  text-align: center;
  color: #fff;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.single-committee-member .commitee-thumb {
  background-color: #000;
}

.single-committee-member img {
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.single-committee-member h3 {
  background-color: #3b5998;
  /* font-size: 2.6rem;
    line-height: 1; */
  font-weight: 700;
  text-transform: uppercase;
  padding: 35px 5px;
  margin: -90px 25px 0;
  z-index: 1;
  position: relative;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.single-committee-member h3 .committee-deg {
  display: block;
  /* font-size: 2.2rem;
    line-height: 3.5200000000000005rem;
    line-height: 1; */
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 10px;
}

.single-committee-member h3:hover {
  background-color: #161f37;
}

.single-committee-member:hover {
  transform: translateY(-5px);
}

.single-committee-member:hover img {
  -webkit-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  -webkit-opacity: 0.7;
  opacity: 0.7;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=70);
  filter: alpha(opacity=70);
}

.col-lg-3 .single-committee-member h3 {
  /* font-size: 1.6rem;
    line-height: 2.5600000000000005rem;
    line-height: 1; */
  padding: 25px 5px;
  margin-top: -60px;
}

.col-lg-3 .single-committee-member h3 .committee-deg {
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem;
    line-height: 1 */
}

.blog-page-content-wrap {
  background-color: #ecf1f5;
}

.single-blog-content-wrap {
  background-color: #fff;
}

.single-blog-content-wrap .single-blog-meta {
  border-bottom: 1px solid #b4b4b4;
  margin-bottom: 20px;
  padding: 25px;
}

.single-blog-content-wrap .single-blog-meta h2 {
  /* font-size: 2.5rem;
    line-height: 4rem;
    line-height: 1.3; */
  font-weight: 400;
}

.single-blog-content-wrap .single-blog-meta .posting-info {
  color: #6a78a1;
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem;
    line-height: 1; */
  font-style: italic;
  margin-top: 10px;
}

.single-blog-content-wrap .single-blog-meta .posting-info a {
  color: #6a78a1;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.single-blog-content-wrap .single-blog-meta .posting-info a:hover {
  color: #3b5998;
}

.single-blog-content-wrap .blog-details {
  /* line-height: 1.8; */
  padding: 0 25px 25px;
}

.single-blog-content-wrap .blog-details p {
  color: #3a3b3c;
}

.single-blog-content-wrap .blog-details h5 {
  /* font-size: 1.6rem;
    line-height: 2.5600000000000005rem; */
  font-weight: 700;
  margin-bottom: 10px;
}

.single-blog-content-wrap .blog-details img {
  margin-bottom: 25px;
}

.single-blog-content-wrap .post-share {
  background-color: #3b5998;
  padding: 10px 30px;
}

.single-blog-content-wrap .post-share .shareonsocial {
  display: flex;
  align-items: center0;
}

.single-blog-content-wrap .post-share .shareonsocial a {
  width: 35px;
  height: 35px;
  background-color: #fff;
  margin-right: 7px;
  text-align: center;
  /* line-height: 35px; */
  color: #3b5998;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.single-blog-content-wrap .post-share .shareonsocial a:hover {
  background-color: #5f7ec1;
  color: #fff;
}

.single-blog-content-wrap .post-share .shareonsocial span {
  color: #fff;
  font-weight: 700;
  /* font-size: 2.5rem;
    line-height: 4rem;
    line-height: 1; */
  margin-right: 20px;
  margin-top: 5px;
}

.single-blog-content-wrap .post-share .post-like-comm {
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem;
    line-height: 1; */
  margin: 10px 0;
}

.single-blog-content-wrap .post-share .post-like-comm a {
  color: #fff;
  margin-left: 15px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.single-blog-content-wrap .post-share .post-like-comm a i.fa {
  margin-right: 4px;
}

.sidebar-area-wrap {
  margin-bottom: 50px;
}

.sidebar-area-wrap .single-sidebar-wrap {
  background-color: #fff;
  padding: 30px 45px;
  margin-bottom: 30px;
}

.sidebar-area-wrap .single-sidebar-wrap .sidebar-title {
  /* font-size: 2.1rem;
    line-height: 3.3600000000000003rem;
    line-height: 1.2; */
  font-weight: 600;
  margin-bottom: 25px;
}

.sidebar-area-wrap .single-sidebar-wrap:last-child {
  margin-bottom: 0;
}

.sidebar-area-wrap .single-sidebar-wrap .sidebar-body .tags {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-area-wrap .single-sidebar-wrap .sidebar-body .tags a {
  color: #3a3b3c;
  border: 1px solid #b4b4b4;
  padding: 10px 12px;
  margin-right: 5px;
  margin-bottom: 10px;
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  /* font-size: 1.4rem;
    line-height: 1 */
}

.event-thumbnail-carousel,
.single-event-page-content .event-thumbnails .owl-nav div {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
}

.sidebar-area-wrap .single-sidebar-wrap .sidebar-body .tags .active,
.sidebar-area-wrap .single-sidebar-wrap .sidebar-body .tags a:focus,
.sidebar-area-wrap .single-sidebar-wrap .sidebar-body .tags a:hover {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.event-page-content-wrap {
  background-color: #ecf1f5;
}

.event-page-content-wrap .all-event-list .single-upcoming-event {
  background-color: #3b5998;
  color: #fff;
  padding: 30px;
  margin-top: 30px;
}

.event-filter-area {
  margin-bottom: 60px;
}

.event-filter-area .form-inline {
  justify-content: center;
}

.event-filter-area .nice-select {
  height: 54px;
  margin-right: 20px;
  text-align: center;
  background-color: #fff;
  color: #6a78a1;
  font-weight: 700;
  /* font-size: 1.6rem;
    line-height: 2.5600000000000005rem;
    line-height: 54px; */
  text-transform: uppercase;
}

.event-filter-area .nice-select:after {
  border-color: #6a78a1;
  width: 8px;
  height: 8px;
  margin-top: -7px;
}

.event-filter-area .btn-brand {
  padding: 12px 25px;
}

.event-filter-area .btn-brand:active,
.event-filter-area .btn-brand:hover {
  background-color: #263961;
  color: #fff;
}

.single-event-page-content {
  background-color: #ecf1f5;
}

.single-event-page-content .single-event-details h2 {
  /* font-size: 3.6rem;
    line-height: 5.760000000000001rem;
    line-height: 1.3; */
  color: #131c33;
  margin-top: 60px;
  margin-bottom: 20px;
}

.single-event-page-content .single-event-details p {
  line-height: 2;
  margin-bottom: 30px;
}

.single-event-page-content .single-event-details .event-schedul h3 {
  background-color: #3b5998;
  color: #fff;
  /* font-size: 3rem;
    line-height: 4.800000000000001rem;
    line-height: 1.3; */
  font-weight: 500;
  text-align: center;
  padding: 12px;
  margin-bottom: 65px;
  margin-top: 90px;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card {
  margin-top: 10px;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-header {
  background-color: #fff;
  padding: 18px 15px 10px;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-header
  h5 {
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem;
    line-height: 1.3; */
  font-weight: 700;
  color: #131c33;
  cursor: pointer;
  margin-bottom: 0;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-header
  h5
  .event-time {
  background-color: #3b5998;
  color: #fff;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem; */
  padding: 10px 13px;
  margin-right: 20px;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-header
  h5
  i.fa {
  /* font-size: 3rem;
    line-height: 4.800000000000001rem;
    line-height: 1; */
  font-weight: 700;
  color: #6a78a1;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-header
  h5[aria-expanded="false"]
  i.fa-angle-up,
.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-header
  h5[aria-expanded="true"]
  i.fa-angle-down {
  display: none;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-body {
  background-color: #fff;
  padding: 20px 30px 30px;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-body
  p {
  margin-bottom: 10px;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-body
  .speaker-name {
  color: #3b5998;
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem;
    line-height: 1; */
  font-style: italic;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 15px;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-body
  .speaker-name
  strong {
  text-transform: uppercase;
  font-weight: 700;
  color: #3a3b3c;
  font-style: normal;
}

.single-event-page-content
  .single-event-details
  .event-schedul
  .event-schedul-accordion
  .card
  .card-body
  .speaker-name
  .speaker-deg {
  color: #6a78a1;
  border-bottom: 2px solid #6a78a1;
  margin-bottom: 0;
}

.single-event-page-content .single-event-details .event-thumb-item {
  min-height: 500px;
  max-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.single-event-page-content .single-event-details .event-thumb-item:after {
  background-color: #000;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  -moz-opacity: 0.2;
  -khtml-opacity: 0.2;
  -webkit-opacity: 0.2;
  opacity: 0.2;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=20);
  filter: alpha(opacity=20);
  z-index: -1;
}

.single-event-page-content .single-event-details .event-thumb-item .event-meta {
  background-color: rgba(0, 0, 0, 0.8);
  border-top: 2px solid #263961;
  color: #fff;
  padding: 15px 30px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.single-event-page-content
  .single-event-details
  .event-thumb-item
  .event-meta
  .event-address {
  color: #ffe156;
  text-decoration: underline;
  margin: 10px 0 5px;
}

.single-event-page-content
  .single-event-details
  .event-thumb-item
  .event-meta
  h3 {
  /* font-size: 2.4rem;
    line-height: 3.84rem;
    line-height: 1.3; */
  margin-bottom: 0;
}

.single-event-page-content
  .single-event-details
  .event-thumb-item
  .event-meta
  .btn-join {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  border: 1px solid #fff;
  padding: 8px 30px;
  text-align: right;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.single-event-page-content
  .single-event-details
  .event-thumb-item
  .event-meta
  .btn-join:active,
.single-event-page-content
  .single-event-details
  .event-thumb-item
  .event-meta
  .btn-join:hover {
  color: #3b5998;
}

.single-event-page-content .event-thumb-img-1 {
  /* background-image: url(../../../assets/img/blog/author.jpg); */
}

.single-event-page-content .event-thumbnails {
  position: relative;
}

.single-event-page-content .event-thumbnails .event-countdown {
  padding: 20px 50px;
  text-align: center;
  background-color: #3b5998;
  border: 1px solid #fff;
  border-top: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.single-event-page-content .event-thumbnails .event-countdown P {
  position: static;
  color: #fff;
  margin-bottom: 0;
}

.single-event-page-content
  .event-thumbnails
  .event-countdown
  .counter-item:last-child {
  margin-right: 0;
}

.single-event-page-content .event-thumbnails .owl-nav div {
  width: 50px;
  height: 50px;
  text-align: center;
  /* font-size: 2rem;
    line-height: 3.2rem;
    line-height: 50px; */
  background-color: #3b5998;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -25px;
  transition: all 0.4s ease-out;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  visibility: hidden;
}

.single-event-page-content .event-thumbnails .owl-nav div.owl-next {
  left: auto;
  right: 20px;
}

.event-countdown {
  margin-bottom: 18px;
  position: relative;
  display: inline-block;
}

.event-countdown .event-countdown-counter {
  display: flex;
}

.event-countdown .event-countdown-counter .counter-item {
  text-align: center;
  color: #fff;
  margin-right: 15px;
}

.event-countdown .event-countdown-counter .counter-item .counter-label {
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem;
    line-height: 1; */
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}

.event-countdown .event-countdown-counter .counter-item .single-cont {
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem;
    line-height: 1; */
  font-weight: 700;
  border: 1px solid #fff;
  background-color: #161f37;
  padding: 10px;
  display: inline-block;
}

.event-countdown p {
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem;
    line-height: 1; */
  font-weight: 700;
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 100%;
}

.event-thumbnail-carousel {
  transition: all 0.4s ease-out;
}

.event-thumbnail-carousel:hover .owl-nav div {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  visibility: visible;
  left: 0;
}

.directory-page-content-warp
  .directory-text-wrap
  .table-search-area
  form
  .nice-select
  .list,
.event-thumbnail-carousel:hover .owl-nav div.owl-next {
  right: 0;
  left: auto;
}

.directory-page-content-warp .directory-text-wrap h2 {
  /* font-size: 4.8rem;
    line-height: 7.68rem;
    line-height: 1; */
  font-weight: 300;
  margin-bottom: 70px;
  color: #3a3b3c;
}

.directory-page-content-warp .directory-text-wrap h2 strong {
  color: #3b5998;
}

.directory-page-content-warp .directory-text-wrap .table-search-area form {
  /* line-height: 1 */
}

.directory-page-content-warp
  .directory-text-wrap
  .table-search-area
  form
  input {
  border: 1px solid #3b5998;
  padding: 15px 20px 16px;
  color: #3a3b3c;
}

.directory-page-content-warp
  .directory-text-wrap
  .table-search-area
  form
  input:focus {
  box-shadow: none;
  outline: 0;
}

.directory-page-content-warp
  .directory-text-wrap
  .table-search-area
  form
  .nice-select {
  padding: 0;
  height: 52px;
  /* line-height: 50px; */
  width: 95px;
  text-align: center !important;
  float: none;
  margin-left: -4px;
}

.directory-page-content-warp
  .directory-text-wrap
  .table-search-area
  .nice-select,
.directory-page-content-warp .directory-text-wrap .table-search-area button,
.directory-page-content-warp .directory-text-wrap .table-search-area input {
  display: inline-block;
  vertical-align: middle;
}

.directory-page-content-warp
  .directory-text-wrap
  .table-search-area
  .btn-brand {
  padding: 17px 30px;
  font-weight: 500;
  /* font-size: 1.5rem;
    line-height: 2.4000000000000004rem;
    line-height: 1; */
  margin-left: 10px;
}

.directory-page-content-warp
  .directory-text-wrap
  .table-search-area
  .btn-brand:focus,
.directory-page-content-warp
  .directory-text-wrap
  .table-search-area
  .btn-brand:hover {
  background-color: #161f37;
  color: #fff;
}

.directory-page-content-warp .directory-text-wrap .show-memeber {
  color: #131c33;
  margin-top: 8px;
}

.directory-page-content-warp .directory-text-wrap .show-memeber span {
  color: #3b5998;
}

.directory-page-content-warp .pagination-wrap {
  margin-top: 30px;
}

.directory-table {
  margin-top: 50px;
}

.directory-table .table thead {
  background-color: #3b5998;
  color: #fff;
}

.directory-table .table thead th {
  padding: 15px 20px;
  /* font-size: 2rem;
    line-height: 3.2rem; */
  font-weight: 700;
}

.directory-table .table tbody tr td {
  background-color: #ecf1f5;
  color: #3a3b3c;
  font-weight: 500;
  /* font-size: 1.8rem;
    line-height: 2.8800000000000003rem;
    line-height: 1; */
  vertical-align: middle;
  padding: 17px 10px;
}

.directory-table .table tbody tr td img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  margin-right: 10px;
  border: 2px solid #5f7ec1;
}

.directory-table .table tbody tr td:nth-child(2n) {
  background-color: #fff;
}

.directory-table .table tbody tr td:first-child {
  width: 35%;
  text-align: left;
}

.register-page-inner {
  -webkit-box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 60px 0 50px;
}

.register-form-content .signin-area-wrap h4 {
  /* font-size: 2.4rem;
    line-height: 3.84rem;
    line-height: 1; */
  color: #131c33;
  margin-bottom: 30px;
}

.register-form-content .signin-area-wrap .sign-form input {
  width: 100%;
  display: block;
  text-align: center;
  background-color: #ecf1f5;
  border: 1px solid #263961;
  color: #6a78a1;
  padding: 12px 15px;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.register-form-content .signin-area-wrap .sign-form input:focus {
  outline: 0;
}

.register-form-content .signin-area-wrap .sign-form .btn-reg {
  width: 100%;
  padding: 15px 20px;
  margin-top: 20px;
}

.register-form-content .register-form-wrap {
  position: relative;
}

.register-form-content .register-form-wrap h3 {
  /* font-size: 2.4rem;
    line-height: 3.84rem;
    line-height: 1; */
  text-transform: uppercase;
  font-weight: 700;
  color: #131c33;
  margin-bottom: 35px;
}

.register-form-content .register-form-wrap .file-input {
  margin: 20px 0 25px;
}

.register-form-content .register-form-wrap .file-input .custom-file {
  background-color: #dbe5f3;
  /* font-size: 1.2rem;
    line-height: 1.92rem;
    line-height: 1; */
  padding: 15px 25px 25px;
  border: 1px solid #6a78a1;
  width: auto;
  cursor: pointer;
}

.register-form-content .register-form-wrap .file-input .custom-file i.fa {
  margin-right: 5px;
}

.register-form-content .register-form-wrap:before {
  background-color: #3b5998;
  content: "";
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -55px;
}

.register-form-content .register-form-wrap .gender {
  margin-bottom: 25px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem;
    line-height: 1; */
  color: #000;
  font-weight: 500;
}

.form-group .form-control {
  padding: 10px 15px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  /* font-size: 1.4rem;
    line-height: 2.2399999999999998rem;
    line-height: 1 */
}

.form-group .form-control:focus {
  outline: 0;
  border-color: #263961;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.form-group .custom-checkbox {
  margin-left: 30px;
  margin-top: 10px;
}

.form-group .custom-checkbox label {
  padding-left: 5px;
  /* font-size: 1.2rem;
    line-height: 1.92rem;
    line-height: 1.2; */
  color: #3a3b3c;
}

.career-page-wrapper .career-page-topbg {
  background-color: #3b5998;
  padding: 20px 0;
}

.career-page-wrapper .career-page-topbg img {
  width: 80%;
  margin: auto;
  display: block;
}

.career-page-wrapper .career-page-content-wrap {
  background-color: #ecf1f5;
}

.career-page-wrapper .career-page-content-wrap .job-opportunity-wrapper {
  margin-top: -30px;
}

.career-page-wrapper .career-page-content-wrap .single-job-opportunity {
  background-color: #fff;
}

.career-page-wrapper .career-page-content-wrap .event-filter-area {
  margin-bottom: 100px;
}

.contact-content-inner {
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
}

.contact-content-inner .map-area-wrap {
  height: 100%;
}

.contact-content-inner .map-area-wrap iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.contact-content-inner .contact-form-wrap {
  padding: 80px 15px;
}

.contact-content-inner .contact-form-wrap h3 {
  /* font-size: 2.4rem;
    line-height: 3.84rem;
    line-height: 1; */
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.contact-content-inner .contact-form-wrap .form-group label.error {
  color: #e74c3c;
}

.contact-content-inner .contact-form-wrap .custom-checkbox {
  margin-bottom: 15px;
}

.contact-content-inner
  .contact-form-wrap
  .custom-checkbox
  .custom-control-label {
  /* line-height: 1; */
  padding-left: 10px;
}

.contact-content-inner .contact-form-wrap .alert {
  margin-bottom: 0;
  margin-top: 8px;
}

.contact-content-inner .contact-form-wrap .alert .close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  /* font-size: 2rem;
    line-height: 3.2rem; */
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

@media only screen and (min-width: 320px) and (max-width: 769px) {
  #page-content-wrap .about-page-content-wrap .single-about-text img,
  .typography-page-wrapper img {
    max-width: 100%;
  }

  .btn-brand,
  .btn-brand-rev {
    padding: 8px 15px;
  }

  .section-padding {
    padding: 5rem 0;
  }

  .pagination-wrap {
    margin-top: 50px;
  }

  .pagination-wrap .pagination li {
    margin-right: 0;
  }

  .pagination-wrap .pagination li a {
    width: 30px;
    height: 30px;
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem;
        line-height: 30px */
  }

  .img-left,
  .img-right {
    float: none;
    padding: 0;
  }

  .offcanvas-added #menucontent.navbar-collapse.collapse.show {
    display: none !important;
  }

  #header-area .preheader-area {
    padding: 15px 0;
  }

  #header-area .preheader-area .preheader-left {
    padding: 0;
  }

  #header-area .preheader-area .preheader-left a {
    display: block;
    margin-right: 0;
    /* font-size: 1.2rem;
        line-height: 1.92rem */
  }

  #header-area .preheader-area .preheader-left a strong {
    /* font-size: 1.2rem;
        line-height: 1.92rem */
  }

  #header-area .preheader-area .preheader-right {
    margin-top: 5px;
  }

  #header-area .preheader-area .preheader-right .btn-auth {
    padding: 4px 8px;
    /* font-size: 1.2rem;
        line-height: 1.92rem; */
    font-weight: 400;
    margin-left: 0;
  }

  #header-area .header-bottom-area,
  #header-area .header-bottom-area.fixed {
    padding: 10px 0;
  }

  #header-area .header-bottom-area .main-menu .navbar-brand img,
  #header-area .header-bottom-area.fixed .main-menu .navbar-brand img {
    width: 150px;
  }

  #header-area .header-bottom-area .main-menu ul,
  #header-area .header-bottom-area.fixed .main-menu ul {
    margin-top: 20px;
  }

  #header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link,
  #header-area .header-bottom-area.fixed .main-menu .navbar-nav li a.nav-link {
    padding: 8px 20px;
    /* font-size: 1.3rem;
        line-height: 2.08rem; */
    margin-right: 0;
  }

  #header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link:before,
  #header-area
    .header-bottom-area.fixed
    .main-menu
    .navbar-nav
    li
    a.nav-link:before {
    height: 2px;
  }

  #header-area .header-bottom-area .main-menu .dropdown-menu,
  #header-area .header-bottom-area.fixed .main-menu .dropdown-menu {
    margin-left: 40px !important;
  }

  #slider-area .single-slide-wrap {
    padding: 80px 0 180px;
  }

  #slider-area .single-slide-wrap .slider-content h2 {
    line-height: 1.3rem;
    line-height: 1;
    font-size: 2.8rem;
  }

  #slider-area .single-slide-wrap .slider-content h3 {
    font-size: 1.5rem;
    line-height: 4rem;
    line-height: 1.1;
    font-weight: 500;
  }
  #slider-area .single-slide-wrap .slider-content p{
    font-weight:500;
    /* text-align:justify; */
    padding-right: 1rem;
  }
  /* 
  #slider-area .owl-dots {
    top: auto;
    bottom: 80px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  #slider-area .owl-dots .owl-dot {
    display: table-cell;
    vertical-align: middle;
  } */

  #slider-area .owl-dots .owl-dot span {
    margin-bottom: 0;
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }

  #slider-area .owl-dots .owl-dot.active span {
    width: 17px;
    height: 17px;
  }

  #slider-area .social-networks-icon {
    display: none;
  }

  #upcoming-area {
    margin-top: 0;
    padding: 70px 0;
  }

  #upcoming-area .upcoming-event-wrap {
    padding: 40px 10px;
  }

  #upcoming-area .upcoming-event-wrap .up-event-titile {
    right: 50%;
    transform: translateX(50%);
  }

  #upcoming-area .upcoming-event-wrap .up-event-titile h3 {
    padding: 10px 20px;
    /* font-size: 1.8rem;
        line-height: 3.2rem; */
    width: auto;
  }

  #upcoming-area .upcoming-event-wrap .upcoming-event-content .owl-nav {
    right: 25px;
    bottom: -7px;
  }

  #upcoming-area .upcoming-event-wrap .upcoming-event-content .owl-nav div {
    display: inline-block;
  }

  .single-upcoming-event .up-event-text {
    margin-top: 35px;
  }

  .single-upcoming-event .up-event-text h3 {
    /* font-size: 2rem;
        line-height: 3.2rem;
        line-height: 1.2; */
    margin-bottom: 25px;
  }

  #about-area .about-area-wrapper:before {
    display: none;
  }

  #about-area .about-area-wrapper .about-content-wrap {
    padding: 30px 10px;
    border-width: 2px;
  }

  #about-area .about-area-wrapper .about-content-wrap .about-thumb {
    display: block;
    margin-bottom: 30px;
  }

  #responsibility-area .single-responsibility {
    margin-bottom: 30px;
  }

  #responsibility-area .col-lg-3:last-child .single-responsibility {
    margin-bottom: 0;
  }

  #funfact-area {
    padding: 70px 0;
  }

  .gallery-menu a,
  .gallery-menu span {
    padding: 8px 15px;
    margin-right: 3px;
    /* font-size: 1.2rem;
        line-height: 1.92rem */
  }

  #funfact-area .single-funfact-wrap {
    margin-bottom: 50px;
  }

  #funfact-area .col-lg-3:last-child .single-funfact-wrap {
    margin-bottom: 0;
  }

  .gallery-menu span {
    margin-bottom: 10px;
  }

  #blog-area .col-lg-4:last-child .single-blog-post,
  .blog-page-contant-start .col-lg-6:last-child .single-blog-post,
  .gallery-menu a {
    margin-bottom: 0;
  }

  #scholership-promo {
    padding: 70px 0;
  }

  #scholership-promo .scholership-promo-text {
    /* font-size: 1.5rem;
        line-height: 2.4000000000000004rem */
  }

  #scholership-promo .scholership-promo-text h2 {
    /* font-size: 2.2rem;
        line-height: 3.5200000000000005rem */
  }

  .single-blog-post {
    margin-bottom: 30px;
  }

  .single-blog-post .blog-content {
    padding: 20px 10px;
  }

  .single-blog-post .blog-content h3 {
    /* font-size: 1.7rem;
        line-height: 2.72rem;
        line-height: 1.4; */
    margin-bottom: 15px;
  }

  #footer-area .footer-widget .col-lg-2 .single-widget-wrap {
    margin-bottom: 0rem;
  }

  #footer-area .footer-bottom .footer-bottom-text {
    font-size: 0.5rem;
    line-height: 1.2399999999999998rem;
  }

  .gallery-page-wrap .single-album-wraper {
    margin-bottom: 70px;
  }

  .gallery-page-wrap .single-album-wraper .album-heading h2 {
    /* font-size: 2.6rem;
        line-height: 4.16rem */
  }

  .gallery-page-wrap .single-album-wraper .album-heading a.btn-brand {
    position: inherit;
  }
  /* 
  .committee-content-wrap .committee-member-list {
    margin-top: -50px;
  }

  .single-committee-member {
    margin-top: 50px;
  }

  .single-committee-member h3 {
    font-size: 1.6rem;
        line-height: 2.5600000000000005rem;
        line-height: 1;
    padding: 25px 5px;
    margin-top: -60px;
  } 

  .single-committee-member h3 .committee-deg {
    font-size: 1.4rem;
        line-height: 2.2399999999999998rem;
        line-height: 1
  }*/

  #page-content-wrap .about-page-content-wrap {
    padding-top: 130px;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text {
    padding: 40px 20px 20px;
    /* font-size: 2.4rem;
        line-height: 2.2399999999999998rem */
  }

  #page-content-wrap .about-page-content-wrap .single-about-text .year {
    /* font-size: 7.2rem;
        line-height: 11.520000000000001rem */
  }

  #page-content-wrap .about-page-content-wrap .single-about-text .img-left,
  #page-content-wrap .about-page-content-wrap .single-about-text .img-right {
    margin: 0 0 25px;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text h2 {
    /* font-size: 1.8rem;
        line-height: 2.8800000000000003rem;
        line-height: 1.3; */
    margin-bottom: 15px;
  }

  #page-content-wrap
    .about-page-content-wrap
    .single-about-text:nth-child(2n)
    .about-page-img {
    margin-top: 30px;
  }

  #page-content-wrap .about-page-area-title h2 {
    /* font-size: 2.4rem;
        line-height: 3.84rem;
        line-height: 1.2 */
  }

  #page-content-wrap .people-to-say {
    padding-bottom: 130px;
  }

  #page-content-wrap .people-to-say .single-testimonial-wrap h4 {
    /* font-size: 2rem;
        line-height: 3.2rem;
        line-height: 1 */
  }

  #page-title-area .page-title-content h2 {
    /* font-size: 3rem;
        line-height: 4.800000000000001rem;
        line-height: 1 */
  }

  .directory-page-content-warp .directory-text-wrap h2 {
    /* font-size: 2.1rem;
        line-height: 3.3600000000000003rem;
        line-height: 1; */
    margin-bottom: 30px;
  }

  .directory-page-content-warp
    .directory-text-wrap
    .table-search-area
    form
    input {
    display: block;
    margin: 0 auto 20px;
  }

  .directory-page-content-warp
    .directory-text-wrap
    .table-search-area
    form
    .btn-brand {
    padding: 12px 15px;
  }

  .directory-page-content-warp
    .directory-text-wrap
    .table-search-area
    form
    .nice-select {
    height: 41px;
    /* line-height: 40px */
  }

  .directory-page-content-warp .directory-text-wrap .directory-table td,
  .directory-page-content-warp .directory-text-wrap .directory-table th {
    /* font-size: 1.3rem;
        line-height: 2.08rem; */
    white-space: nowrap;
  }

  .directory-page-content-warp .directory-text-wrap .directory-table td img,
  .directory-page-content-warp .directory-text-wrap .directory-table th img {
    width: 40px;
    height: 40px;
  }

  .directory-page-content-warp
    .directory-text-wrap
    .directory-table
    td:first-child,
  .directory-page-content-warp
    .directory-text-wrap
    .directory-table
    th:first-child {
    width: auto;
  }

  .single-blog-content-wrap .single-blog-meta h2 {
    /* font-size: 1.7rem;
        line-height: 2.72rem;
        line-height: 1.4 */
  }

  .single-blog-content-wrap .single-blog-meta .posting-info {
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem;
        line-height: 1 */
  }

  .single-blog-content-wrap .post-share {
    padding: 10px;
  }

  .single-blog-content-wrap .post-share .shareonsocial {
    display: block;
  }

  .single-blog-content-wrap .post-share .shareonsocial span {
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem;
        line-height: 1; */
    margin-right: 5px;
  }

  .single-blog-content-wrap .post-share .shareonsocial a {
    width: 25px;
    height: 25px;
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem;
        line-height: 23px; */
    display: inline-block;
    margin-right: 2px;
  }

  .single-blog-content-wrap .post-share .post-like-comm a {
    margin-left: 5px;
  }

  .event-filter-area .nice-select {
    height: 36px;
    /* font-size: 1.2rem;
        line-height: 1.92rem;
        line-height: 38px; */
    font-weight: 500;
    margin-right: 2px;
    padding-left: 8px;
    padding-right: 10px;
    margin-bottom: 2px;
  }

  .event-filter-area .nice-select:after {
    width: 5px;
    height: 5px;
    margin-top: -2px;
  }

  .event-filter-area .nice-select ul.list {
    /* font-size: 1.2rem;
        line-height: 1.92rem;
        line-height: 1 */
  }

  .event-filter-area .btn-brand {
    /* font-size: 1.2rem;
        line-height: 1.92rem; */
    font-weight: 500;
    padding: 8px 18px;
  }

  .single-event-page-content .single-event-details h2 {
    /* font-size: 2.5rem;
        line-height: 4rem;
        line-height: 1.3 */
  }

  .single-event-page-content
    .single-event-details
    .event-schedul
    .event-schedul-accordion
    .card
    .card-header
    h5 {
    /* font-size: 1.5rem;
        line-height: 2.4000000000000004rem */
  }

  .single-event-page-content
    .single-event-details
    .event-schedul
    .event-schedul-accordion
    .card
    .card-header
    h5
    .event-time {
    text-align: center;
    display: block;
    margin-bottom: 5px;
  }

  .single-event-page-content
    .single-event-details
    .event-schedul
    .event-schedul-accordion
    .card
    .card-body {
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem */
  }

  .single-event-page-content
    .single-event-details
    .event-schedul
    .event-schedul-accordion
    .card
    .card-body
    .speaker-name {
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem;
        line-height: 1.2 */
  }

  .single-event-page-content
    .single-event-details
    .event-thumb-item
    .event-meta
    .btn-join {
    position: static;
    transform: none;
    margin-top: 10px;
    display: inline-block;
  }

  .single-event-page-content
    .single-event-details
    .event-thumb-item
    .event-meta
    h3 {
    /* font-size: 1.5rem;
        line-height: 2.4000000000000004rem */
  }

  .single-event-page-content
    .single-event-details
    .event-thumb-item
    .event-meta
    a {
    /* font-size: 1.3rem;
        line-height: 2.08rem;
        line-height: 1.3; */
    display: block;
  }

  .register-form-content .register-form-wrap {
    margin-top: 100px;
  }

  .contact-content-inner .map-area-wrap {
    min-height: 400px;
  }

  .event-countdown p {
    display: none;
  }

  .single-event-page-content .event-thumbnails {
    position: relative;
  }

  .single-event-page-content .event-thumbnails .event-countdown {
    padding: 20px;
    text-align: center;
    background-color: #3b5998;
    border: 1px solid #fff;
    border-top: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }

  .single-event-page-content .event-thumbnails .event-countdown P {
    position: static;
    color: #fff;
    margin-bottom: 0;
  }

  .page-title-content h1 {
    /* font-size: 3rem;
        line-height: 4.800000000000001rem;
        line-height: 1.3 */
  }

  .register-form .form-group .custom-checkbox {
    margin-bottom: 20px;
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) {
  #page-content-wrap .about-page-content-wrap .single-about-text img,
  .typography-page-wrapper img {
    max-width: 100%;
  }

  .btn-brand,
  .btn-brand-rev {
    padding: 10px 25px;
    /* font-size: 1.5rem;
        line-height: 2.4000000000000004rem */
  }

  .section-padding {
    padding: 70px 0;
  }

  .img-left,
  .img-right {
    float: none;
    padding: 0;
  }

  .offcanvas-added #menucontent.navbar-collapse.collapse.show {
    display: none !important;
  }

  #header-area .preheader-area {
    padding: 15px 0;
  }

  #header-area .preheader-area .preheader-left {
    padding: 4px 0;
    margin-bottom: 0;
  }

  #header-area .preheader-area .preheader-left a {
    display: inline-block;
    margin-right: 10px;
    /* font-size: 1.2rem;
        line-height: 1.92rem */
  }

  #header-area .preheader-area .preheader-left a strong {
    /* font-size: 1.3rem;
        line-height: 2.08rem */
  }

  #header-area .preheader-area .btn-auth {
    padding: 8px 20px;
    /* font-size: 1.2rem;
        line-height: 1.92rem */
  }

  #header-area .header-bottom-area {
    padding: 10px 0;
  }

  #header-area .header-bottom-area .main-menu .navbar-brand img {
    width: 150px;
  }

  #header-area .header-bottom-area .main-menu ul {
    margin-top: 20px;
  }

  #header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link {
    padding: 8px 20px;
    /* font-size: 1.3rem;
        line-height: 2.08rem; */
    margin-right: 0;
  }

  #header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link:before {
    height: 2px;
  }

  #header-area .header-bottom-area .main-menu .dropdown-menu {
    margin-left: 40px !important;
  }

  #slider-area .single-slide-wrap {
    padding: 80px 0 180px;
  }

  #slider-area .single-slide-wrap .slider-content h2 {
    font-size: 4rem;
    line-height: 6.4rem;
    line-height: 1;
  }

  #slider-area .single-slide-wrap .slider-content h3 {
    font-size: 2.5rem;
    line-height: 4rem;
    line-height: 1.1;
  }

  #slider-area .owl-dots {
    top: auto;
    bottom: 80px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  #slider-area .owl-dots .owl-dot {
    display: table-cell;
    vertical-align: middle;
  }

  #about-area .about-area-wrapper:before,
  #slider-area .social-networks-icon {
    display: none;
  }

  #slider-area .owl-dots .owl-dot span {
    margin-bottom: 0;
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }

  #slider-area .owl-dots .owl-dot.active span {
    width: 17px;
    height: 17px;
  }

  #upcoming-area {
    margin-top: 0;
    padding: 105px 0 70px;
  }

  #upcoming-area .upcoming-event-wrap .up-event-titile {
    right: 50%;
    transform: translateX(50%);
  }

  #upcoming-area .upcoming-event-wrap .up-event-titile h3 {
    padding: 10px 35px;
    /* font-size: 3rem;
        line-height: 4.800000000000001rem; */
    width: auto;
  }

  .single-upcoming-event .up-event-text {
    margin-top: 35px;
  }

  .single-upcoming-event .up-event-text h3 {
    /* font-size: 2.4rem;
        line-height: 3.84rem;
        line-height: 1.3; */
    margin-bottom: 15px;
  }

  .single-upcoming-event .btn-brand {
    margin-bottom: 1px;
  }

  #about-area .about-area-wrapper .about-content-wrap {
    padding: 30px 10px;
    border-width: 2px;
  }

  #about-area .about-area-wrapper .about-content-wrap .about-thumb {
    display: block;
    margin-bottom: 30px;
  }

  #responsibility-area .single-responsibility {
    margin-bottom: 30px;
  }

  #responsibility-area .col-lg-3:nth-child(1n + 3) .single-responsibility {
    margin-bottom: 0;
  }

  #funfact-area .single-funfact-wrap {
    margin-bottom: 50px;
    /* margin-left: 50px */
  }

  #blog-area .col-lg-4:last-child .single-blog-post,
  #funfact-area .col-lg-3:nth-child(1n + 3) .single-funfact-wrap,
  .blog-page-contant-start .col-lg-6:last-child .single-blog-post {
    margin-bottom: 0;
  }

  .gallery-menu a,
  .gallery-menu span {
    padding: 10px 15px;
    margin-right: 5px;
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem; */
    margin-bottom: 0;
  }

  #scholership-promo .scholership-promo-text {
    /* font-size: 1.5rem;
        line-height: 2.4000000000000004rem */
  }

  #scholership-promo .scholership-promo-text h2 {
    /* font-size: 2.6rem;
        line-height: 4.16rem */
  }

  .single-blog-post {
    margin-bottom: 30px;
  }

  .single-blog-post .blog-content {
    padding: 30px;
  }

  .single-blog-post .blog-content h3 {
    font-size: 2rem;
    line-height: 3.2rem;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  #footer-area .footer-widget .col-lg-2 .single-widget-wrap,
  #footer-area .footer-widget .col-lg-3 .single-widget-wrap {
    margin-bottom: 0;
  }

  #footer-area .footer-bottom .footer-bottom-text {
    /* font-size: 1.6rem;
        line-height: 2.5600000000000005rem */
  }

  .gallery-page-wrap .single-album-wraper .album-heading a.btn-brand {
    position: inherit;
  }

  .committee-content-wrap .committee-member-list {
    margin-top: -50px;
  }

  .single-committee-member {
    margin-top: 50px;
  }

  .single-committee-member h3 {
    /* font-size: 1.8rem;
        line-height: 2.8800000000000003rem;
        line-height: 1; */
    padding: 20px 5px;
    margin-top: -50px;
  }

  .single-committee-member h3 .committee-deg {
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem;
        line-height: 1 */
  }

  #page-content-wrap .about-page-content-wrap .single-about-text {
    padding: 40px 20px 20px;
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem */
  }

  #page-content-wrap .about-page-content-wrap .single-about-text .year {
    /* font-size: 7.2rem;
        line-height: 11.520000000000001rem */
  }

  #page-content-wrap .about-page-content-wrap .single-about-text h3 {
    /* font-size: 1.8rem;
        line-height: 2.8800000000000003rem;
        line-height: 1.3; */
    margin-bottom: 15px;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text .img-left,
  #page-content-wrap .about-page-content-wrap .single-about-text .img-right {
    margin: 0 0 25px;
  }

  #page-content-wrap
    .about-page-content-wrap
    .single-about-text:nth-child(2n)
    .about-page-img {
    margin-top: 30px;
  }

  .directory-page-content-warp
    .directory-text-wrap
    .table-search-area
    form
    input {
    display: inline-block;
    margin: 0;
  }

  .directory-page-content-warp
    .directory-text-wrap
    .table-search-area
    form
    .btn-brand {
    padding: 17px 30px;
  }

  .directory-page-content-warp
    .directory-text-wrap
    .table-search-area
    form
    .nice-select {
    height: 52px;
    line-height: 52px;
  }

  .directory-page-content-warp .directory-text-wrap .directory-table td,
  .directory-page-content-warp .directory-text-wrap .directory-table th {
    /* font-size: 1.5rem;
        line-height: 2.4000000000000004rem; */
    white-space: nowrap;
  }

  .directory-page-content-warp .directory-text-wrap .directory-table td img,
  .directory-page-content-warp .directory-text-wrap .directory-table th img {
    width: 40px;
    height: 40px;
  }

  .directory-page-content-warp
    .directory-text-wrap
    .directory-table
    td:first-child,
  .directory-page-content-warp
    .directory-text-wrap
    .directory-table
    th:first-child {
    width: auto;
  }

  .event-filter-area .btn-brand {
    padding: 14px 25px;
  }

  .single-event-page-content
    .single-event-details
    .event-thumb-item
    .event-meta
    a {
    display: block;
  }

  .single-event-page-content
    .single-event-details
    .event-thumb-item
    .event-meta
    .btn-join {
    position: static;
    transform: none;
    display: inline-block;
    margin-top: 10px;
  }

  .register-form-content .register-form-wrap {
    margin-top: 100px;
  }

  .register-form-content .register-form-wrap:before {
    display: none;
  }

  .contact-content-inner .map-area-wrap {
    min-height: 500px;
  }

  .register-form .form-group .custom-checkbox {
    margin-bottom: 20px;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .btn-brand,
  .btn-brand-rev {
    padding: 15px 30px;
    /* font-size: 1.8rem;
        line-height: 2.8800000000000003rem */
  }

  .section-padding {
    padding: 70px 0;
  }

  .img-left,
  .img-right {
    float: none;
    padding: 0;
  }

  #header-area .preheader-area .preheader-left a {
    margin-right: 20px;
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem */
  }

  #header-area .preheader-area .btn-auth,
  #header-area .preheader-area .preheader-left a strong {
    /* font-size: 1.6rem;
        line-height: 2.5600000000000005rem */
  }

  #header-area .preheader-area .btn-auth {
    padding: 8px 20px;
  }

  #slider-area .single-slide-wrap {
    padding: 80px 0 180px;
  }

  #slider-area .single-slide-wrap .slider-content h2 {
    /* font-size: 7rem;
        line-height: 11.200000000000001rem;
        line-height: 1 */
  }

  #slider-area .single-slide-wrap .slider-content h3 {
    font-size: 2rem;
    line-height: 4.800000000000001rem;
    line-height: 1.1;
  }

  #upcoming-area {
    padding: 105px 0 70px;
  }

  .single-upcoming-event .up-event-text h3 {
    /* font-size: 3rem;
        line-height: 4.800000000000001rem;
        line-height: 1.3 */
  }

  #about-area .about-area-wrapper .about-content-wrap {
    padding: 40px 30px;
    border-width: 10px;
  }

  #scholership-promo .scholership-promo-text {
    /* font-size: 2rem;
        line-height: 3.2rem */
  }

  #scholership-promo .scholership-promo-text h2 {
    /* font-size: 4rem;
        line-height: 6.4rem */
  }

  .single-blog-post .blog-content {
    padding: 20px 15px;
  }

  .single-blog-post .blog-content h3 {
    /* font-size: 1.7rem;
        line-height: 2.72rem;
        line-height: 1.3 */
  }

  .gallery-page-wrap .single-album-wraper .album-heading a.btn-brand {
    position: absolute;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text {
    padding: 50px;
    /* font-size: 1.6rem;
        line-height: 2.5600000000000005rem */
  }

  #page-content-wrap .about-page-content-wrap .single-about-text .year {
    /* font-size: 10rem;
        line-height: 16rem;
        line-height: 1 */
  }

  #page-content-wrap .about-page-content-wrap .single-about-text h3 {
    /* font-size: 2.2rem;
        line-height: 3.5200000000000005rem;
        line-height: 1.3; */
    margin-bottom: 15px;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text .img-left,
  #page-content-wrap .about-page-content-wrap .single-about-text .img-right {
    margin: 0 0 25px;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text img {
    max-width: 100%;
  }

  #page-content-wrap
    .about-page-content-wrap
    .single-about-text:nth-child(2n)
    .about-page-img {
    margin-top: 30px;
  }

  .blog-page-contant-start .col-lg-6:last-child .single-blog-post,
  .blog-page-contant-start .col-lg-6:nth-child(1n + 5) .single-blog-post {
    margin-bottom: 0;
  }

  .event-filter-area .btn-brand {
    padding: 13px 25px;
  }
}

@media only screen and (min-width: 992px) {
  #page-content-wrap .about-page-content-wrap .single-about-text img,
  .typography-page-wrapper img {
    max-width: 500px;
  }

  .btn-brand,
  .btn-brand-rev {
    padding: 15px 30px;
    /* font-size: 1.8rem;
        line-height: 2.8800000000000003rem; */
    border-radius: 0px;
  }

  .section-padding {
    padding: 11px 0px 80px 0px;
  }

  .img-left,
  .img-right {
    padding: 0 2rem 1rem 0;
  }

  .img-left {
    float: left;
  }

  .img-right {
    float: right;
  }

  #header-area .preheader-area {
    padding: 0;
  }

  #header-area .preheader-area .preheader-left {
    padding: 16px 0;
    margin-bottom: 0;
  }

  #header-area .preheader-area .preheader-left a {
    display: inline-block;
    margin-right: 35px;
    /* font-size: 1.4rem;
        line-height: 2.2399999999999998rem */
  }

  #header-area .preheader-area .preheader-left a strong {
    /* font-size: 1.6rem;
        line-height: 2.5600000000000005rem */
  }

  #header-area .preheader-area .btn-auth {
    padding: 15px 35px;
    /* font-size: 1.8rem;
        line-height: 2.8800000000000003rem */
  }

  #header-area .header-bottom-area {
    padding: 0;
  }

  #header-area .header-bottom-area .main-menu .navbar-brand img {
    width: 250px;
  }

  #header-area .header-bottom-area .main-menu ul {
    margin-top: 0;
  }

  #header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link {
    padding: 40px 20px;
    /* font-size: 1.7rem;
        line-height: 2.72rem; */
    margin-right: 20px;
  }

  #header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link:before {
    height: 6px;
  }
  #header-area .header-bottom-area .main-menu .dropdown-menu {
    margin-left: 0 !important;
  }

  #slider-area .single-slide-wrap {
    padding: 155px 0 320px;
  }

  #slider-area .single-slide-wrap .slider-content h2 {
    font-size: 5rem;
    /* line-height: 1; */
  }

  #slider-area .single-slide-wrap .slider-content h3 {
    font-size: 1.7rem;
    line-height: 1.760000000000001rem;
    line-height: 1.1;
    font-weight: 600;
  }

  #slider-area .owl-dots {
    top: 50%;
    bottom: auto;
    left: auto;
    right: 65px;
    transform: translateY(-50%);
  }

  #slider-area .owl-dots .owl-dot {
    display: inherit;
    vertical-align: middle;
  }

  #about-area .about-area-wrapper:before,
  #slider-area .social-networks-icon {
    display: block;
  }

  #slider-area .owl-dots .owl-dot span {
    margin-bottom: 10px;
    margin-right: 0;
    width: 25px;
    height: 25px;
  }

  #slider-area .owl-dots .owl-dot.active span {
    width: 30px;
    height: 30px;
  }

  #upcoming-area {
    margin-top: -190px;
    padding: 0;
  }

  #upcoming-area .upcoming-event-wrap .up-event-titile {
    right: 40px;
    transform: inherit;
    margin-bottom: 0;
  }

  #upcoming-area .upcoming-event-wrap .up-event-titile h3 {
    padding: 10px 45px;
    /* font-size: 2.4rem;
        line-height: 3.84rem; */
    width: auto;
  }

  .single-upcoming-event .up-event-text {
    margin-top: 0;
  }

  #about-area .about-area-wrapper .section-title {
    margin-bottom: 50px;
  }

  #footer-area .footer-widget .single-widget-wrap,
  #funfact-area .single-funfact-wrap,
  #responsibility-area .single-responsibility,
  .gallery-menu a,
  .gallery-menu span,
  .single-blog-post {
    margin-bottom: 0;
  }

  #about-area .about-area-wrapper .about-content-wrap {
    padding: 36px 10px 40px 260px;
    border-width: 10px;
  }

  #about-area .about-area-wrapper .about-content-wrap .about-thumb {
    display: none;
  }

  .gallery-menu a,
  .gallery-menu span {
    padding: 15px 35px;
    margin-right: 25px;
    /* font-size: 1.6rem;
        line-height: 2.5600000000000005rem */
  }

  .single-blog-post .blog-content {
    padding: 30px;
  }

  .single-blog-post .blog-content h3 {
    /* font-size: 2rem;
        line-height: 3.2rem;
        line-height: 1.4 */
  }

  .committee-content-wrap .committee-member-list {
    margin-top: -80px;
  }

  .single-committee-member {
    margin-top: 80px;
  }

  .single-committee-member h3 {
    /* font-size: 2.6rem;
        line-height: 4.16rem;
        line-height: 1; */
    padding: 35px 5px;
    margin-top: -90px;
  }

  .single-committee-member h3 .committee-deg {
    /* font-size: 2.2rem;
        line-height: 3.5200000000000005rem;
        line-height: 1 */
  }

  #page-content-wrap .about-page-content-wrap .single-about-text {
    padding: 50px;
    /* font-size: 1.6rem;
        line-height: 2.5600000000000005rem */
  }

  #page-content-wrap
    .about-page-content-wrap
    .single-about-text
    .about-page-img {
    position: absolute;
    width: 124%;
    margin-bottom: 0;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text h2 {
    /* font-size: 3.6rem;
        line-height: 5.760000000000001rem;
        line-height: 1.3; */
    margin-bottom: 20px;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text .img-left,
  #page-content-wrap .about-page-content-wrap .single-about-text .img-right {
    margin: -100px 0 0;
  }

  #page-content-wrap
    .about-page-content-wrap
    .single-about-text:nth-child(2n)
    .about-page-img,
  .register-form-content .register-form-wrap {
    margin-top: 0;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text .img-left {
    margin-left: -97px;
  }

  #page-content-wrap .about-page-content-wrap .single-about-text .img-right {
    margin-right: -118px;
  }

  .directory-page-content-warp .directory-text-wrap .directory-table th {
    /* font-size: 2rem;
        line-height: 3.2rem */
  }

  .directory-page-content-warp .directory-text-wrap .directory-table td {
    /* font-size: 1.8rem;
        line-height: 2.8800000000000003rem; */
    white-space: normal;
  }

  .directory-page-content-warp .directory-text-wrap .directory-table td img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
  }

  .directory-page-content-warp
    .directory-text-wrap
    .directory-table
    td:first-child {
    width: 35%;
  }

  .blog-page-contant-start .col-lg-6 .single-blog-post {
    margin-bottom: 30px;
  }

  .blog-page-contant-start .col-lg-6:last-child .single-blog-post,
  .blog-page-contant-start .col-lg-6:nth-child(1n + 5) .single-blog-post,
  .sidebar-area-wrap {
    margin-bottom: 0;
  }

  .single-event-page-content
    .single-event-details
    .event-thumb-item
    .event-meta
    a {
    display: block;
  }

  .single-event-page-content
    .single-event-details
    .event-thumb-item
    .event-meta
    .btn-join {
    position: absolute;
    transform: translateY(-50%);
    display: inline-block;
    margin-top: 0;
  }

  .register-form-content .register-form-wrap:before {
    display: block;
  }

  #header-area .header-bottom-area.fixed .navbar-brand img {
    width: 150px;
  }
}

.cbx-switcher-area {
  position: fixed;
  top: 35%;
  right: 0;
  max-width: 300px;
  z-index: 1891;
}

.cbx-switcher-inner {
  position: relative;
}

.cbx-switcher-btn {
  font-weight: 700;
  background: #3b5998;
  /* font-size: 25px; */
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1892;
  padding: 5px;
}

.cbx-switcher-btn:active,
.cbx-switcher-btn:focus,
.cbx-switcher-btn:hover {
  color: #fff;
}

.cbx-switcher-body {
  background: rgba(230, 230, 230, 0.93);
  padding: 10px;
  width: 260px;
  position: absolute;
  top: 0;
  right: -280px;
}

.cbx-switcher-list li {
  height: 30px;
  width: 30px;
  float: left;
  display: inline-block;
  margin: 0 20px 20px 0;
}

.cbx-switcher-list li a {
  width: 100%;
  height: 100%;
  display: block;
}

.cbx-list-default {
  background: #3b60c9;
}

.cbx-list-red {
  background: #e74c3c;
}

.cbx-list-green {
  background: #2ec887;
}

.cbx-list-blue {
  background: #3b60c9;
}

.cbx-list-olive {
  background: #559137;
}

.cbx-list-violet {
  background: #93c;
}

.cbx-list-pink {
  background: #ff69b4;
}

.cbx-list-navy {
  background: #3b5998;
}

.cbx-list-orange {
  background: #f5740a;
}

.cbx-list-yellow {
  background: #e67e22;
}

/* 
@media only screen and (min-width: 1950px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 100%;
  }
  html {
    font-size: 210%;
  }

  .wmo_logo {
    width: 130px;
    margin-top: 0rem;
    float: left;
  }
  #upcoming-area {
    margin-top: -320px;
    padding: 0;
    margin-right: 23rem;
  }
  #upcoming-area .upcoming-event-wrap {
    background-color: #3b5998;
    padding: 40px;
    color: #fff;
    position: relative;
    width: 60rem;
  }
  .single-upcoming-event .up-event-thumb .up-event-date {
    color: #3a3b3c;
    font-weight: 700;
    background-color: #fff;
    padding: 12px;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: -30px;
    border-bottom: 8px solid #000;
  }
  #about-area .about-area-wrapper .about-content-wrap {
    background-color: #ecf1f5;
    border: 10px solid #dbe5f3;
    padding: 36px 10px 40px 260px;
    width: 42rem;
  }
  #slider-area .single-slide-wrap {
    padding: 275px 0 320px;
  }
}
 */
