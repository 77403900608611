.cont-add {
    grid-template-columns: 18px 1fr;
    display: grid;
    grid-gap: 16px;
    align-items: center;
    background: #f5f5f5;
    border-radius: 4px;
}

.cont-add-lft {
    text-align: left;
}