/* .gallery_item {
 padding: .1rem;
} */

.album {
  animation: AlbumAnimation 1s ease 0s 1 normal forwards;
}

/* Animation for Laptop,desktop devices S,M,L */
@keyframes AlbumAnimation {
  0% {
    opacity: 0;
    transform: translateY(250px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
  max-width: 100% !important;
  margin-bottom: 1rem;
}
.li {
  height: 45vh;
  max-width: 100%;
  flex-grow: 1;
}
.li:last-child {
  flex-grow: 0;
}
.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
@media (max-aspect-ratio: 1) {
  .li {
    height: 35vh;
  }
}
@media (max-height: 480px) {
  .li {
    height: 85vh;
  }
}
@media (max-aspect-ratio: 1) and (max-width: 480px) {
  .ul {
    flex-direction: row;
  }
  .li {
    height: auto;
    width: 100%;
  }
  .img {
    width: 100%;
    min-width: 0;
  }
}
