.slide-bg-1 {
  /* background: url("../../../assets/img/gallery/college-pic/img-15.jpg") */
  background: #000 no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 120vh;
  animation: bannerAnim 3.5s ease 0s 1 normal forwards;
}
.slider-content {
  position: absolute;
  top: 20vh;
  color: #fff;
  text-align: center;
  z-index: 1;
}

#slider-area .single-slide-wrap .slider-content h2 {
  animation: bannerMainHead 3.5s ease 0s 1 normal forwards;
}
#slider-area .single-slide-wrap .slider-content h3 {
  animation: bannerSubHead 3.5s ease 0s 1 normal forwards;
}
#slider-area .single-slide-wrap .slider-content p {
  animation: bannerButton1 3.5s ease 0s 1 normal forwards;
}
#slider-area .single-slide-wrap .slider-content .slider-btn a {
  animation: bannerButton1 3.5s ease 0s 1 normal forwards;
}

/* Banner initial loading animation */
@media only screen and (min-device-width: 768px) {
  @keyframes bannerAnim {
    0% {
      filter: blur(3px);
    }
    25% {
      filter: blur(2.5px);
    }
    50% {
      filter: blur(1.5px);
    }
    100% {
      filter: blur(0px);
    }
  }

  @keyframes bannerMainHead {
    0% {
      opacity: 0;
      transform: translateY(-250px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes bannerSubHead {
    0% {
      opacity: 0;
      transform: translateX(-250px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes bannerSuperSubHead {
    0% {
      opacity: 0;
      transform: translateX(250px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes bannerButton1 {
    0% {
      opacity: 0;
      transform: translateY(250px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

/* ________________________________________________________________________________________ */
