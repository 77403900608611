  #funfact-area {
    background-color: #161f37;
    padding: 100px 0;
    color: #fff;
  }
#funfact-area .single-funfact-wrap .funfact-icon {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
}

#funfact-area .single-funfact-wrap .funfact-info {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    padding-left: 35px;
}

#funfact-area .single-funfact-wrap .funfact-info h5 {
    font-weight: 300;
    line-height: 100%;
    margin: 0;
}

.funfact-info p {
    margin-bottom: 0;
}

.funfact-icon img {
    width: 3rem;
}

@media only screen and (min-width: 320px) and (max-width: 769px) {
    #funfact-area .single-funfact-wrap {
        margin-bottom: 50px;
    }
}