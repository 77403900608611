.nav_title {
    float: left;
    margin-top: 0rem;
}

#nav_title_sub {
    color: blue;
}

.wmo_logo {
    width: 70px;
    margin-top: 0rem;
    float: left;
}

.menu_icon {
    border: none;
}

.item_2{
    border-bottom: 5px solid white;
    padding-top: 4px;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
}

.item_1{
    background-color: rgba(0, 119, 255, 0.224);
    border-bottom: 5px solid  #1d3f9a;
    padding-top: 4px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: .2rem;
    cursor: pointer;
    font-weight: bold;
}
.item_2:hover {
    background-color: rgba(0, 119, 255, 0.224);
    border-bottom: 5px solid  #1d3f9a;
}
 